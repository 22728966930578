import { useEffect, useState } from 'react'
import axios from 'axios'
import config from '../config'
import { BatchStatus } from '../lib/Utils'

type useBatchStatusOptions = {
  resourceId: string
  batchName: 'job-to-subsegments' | 'create-translation-kit' | 'confirm-translations'
  polling: boolean
}

type useBatchStatus = (
  options: useBatchStatusOptions
) => {
  batchStatus: BatchStatus
  loading: boolean
  error: Error | null
}
export const useBatchStatus: useBatchStatus = ({ resourceId, batchName, polling }) => {
  const [batchStatus, setBatchStatus] = useState<BatchStatus>(BatchStatus.NONE)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    const describeBatchJob = async (): Promise<void> => {
      if (!resourceId || !batchName) {
        return
      }

      if (!polling) {
        setLoading(true)
      }
      try {
        const result = await axios.post<{ job: { status: BatchStatus } }>(
          `${config[config.STAGE].endpoint}/api/v1/describeBatchJob`,
          {
            resourceId,
            name: batchName
          }
        )
        if (result && result.data && result.data.job) {
          setBatchStatus(result.data.job.status || BatchStatus.NONE)
        } else {
          setBatchStatus(BatchStatus.NONE)
        }
        setError(null)
      } catch (error) {
        console.log(JSON.stringify(error, null, 2))
        setError(error)
      }
      if (!polling) {
        setLoading(false)
      }
    }
    describeBatchJob()
    if (polling) {
      const describeBatchJobTimer = setInterval(describeBatchJob, 15000)
      return (): void => clearInterval(describeBatchJobTimer)
    }
  }, [resourceId, batchName, polling])

  return { batchStatus, loading, error }
}
