import { ColDef } from '@ag-grid-community/core'

import {
  JobNameCellRenderer,
  ProjectNameCellRenderer,
  UnitStatisticsCellRenderer
} from '../../lib/CellRenderers'
import {
  ContentTypeFormatter,
  DateTimeFormatter,
  EmptyCellFormatter,
  JobNameFormatter,
  JobTypeFormatter,
  LanguageNameFormatter,
  StatusNameFormatter
} from '../../lib/ValueFormatters'

const useColumnDefs = ({
  showCheckBoxes,
  showDownloadButtonColumn,
  showOpenEditorButtonColumn,
  showProjectInfoColumns,
  showUnitStatisticsColumns
}: {
  showCheckBoxes?: boolean
  showDownloadButtonColumn?: boolean
  showOpenEditorButtonColumn?: boolean
  showProjectInfoColumns?: boolean
  showUnitStatisticsColumns?: boolean
}): { columnDefs: ColDef[]; defaultColDef: ColDef } => {
  const defaultColDef: ColDef = {
    lockVisible: true,
    minWidth: 50,
    resizable: true,
    sortable: false,
    width: 200
  }

  const columnDefs: ColDef[] = [
    {
      colId: '_id',
      headerName: '',
      field: '_id',
      checkboxSelection: true,
      maxWidth: 50,
      minWidth: 50,
      resizable: false,
      valueFormatter: EmptyCellFormatter,
      width: 50,
      hide: !showCheckBoxes
    },
    {
      colId: 'name',
      headerName: 'ファイル名',
      field: 'name',
      cellRenderer: JobNameCellRenderer,
      width: 300,
      valueFormatter: JobNameFormatter
    },
    {
      colId: 'unitStatistics',
      headerName: '未翻訳セグメント数',
      hide: !showUnitStatisticsColumns,
      cellRenderer: UnitStatisticsCellRenderer,
      width: 150
    },
    {
      colId: 'editor',
      headerName: 'エディター',
      hide: !showOpenEditorButtonColumn,
      cellRenderer: 'OpenEditorButtonRenderer',
      width: 100
    },
    {
      colId: 'download',
      headerName: 'ダウンロード',
      hide: !showDownloadButtonColumn,
      cellRenderer: 'DownloadButtonRenderer',
      width: 100
    },
    {
      colId: 'contentType',
      headerName: 'ファイルタイプ',
      field: 'contentType',
      valueFormatter: ContentTypeFormatter
    },
    { colId: 'origin', headerName: '区分', field: 'origin', valueFormatter: JobTypeFormatter },
    { colId: 'subjectId', headerName: '案件ID', field: 'subjectId' },
    {
      colId: 'srcLang',
      headerName: 'ソース言語',
      hide: !showProjectInfoColumns,
      field: 'srcLang',
      valueFormatter: LanguageNameFormatter
    },
    {
      colId: 'tgtLang',
      headerName: 'ターゲット言語',
      hide: !showProjectInfoColumns,
      field: 'tgtLang',
      valueFormatter: LanguageNameFormatter
    },
    { colId: 'fromSubsidiaryCode', headerName: '引用元サイト', field: 'fromSubsidiaryCode' },
    { colId: 'toSubsidiaryCode', headerName: '引用先サイト', field: 'toSubsidiaryCode' },
    { colId: 'categoryCode', headerName: 'カテゴリ', field: 'categoryCode' },
    { colId: 'brandCode', headerName: 'ブランド', field: 'brandCode' },
    {
      colId: 'status',
      headerName: 'ステータス',
      field: 'status',
      valueFormatter: StatusNameFormatter
    },
    {
      colId: 'parentName',
      headerName: '見積束ねプロジェクト名',
      hide: !showProjectInfoColumns,
      field: 'parentName',
      cellRenderer: ProjectNameCellRenderer,
      width: 150
    },
    {
      colId: 'vendor',
      headerName: '発注先翻訳会社',
      hide: !showProjectInfoColumns,
      field: 'vendor'
    },
    { colId: 'owner', headerName: '担当者', hide: !showProjectInfoColumns, field: 'owner' },
    {
      colId: 'ownerGroup',
      headerName: '担当者所属',
      hide: !showProjectInfoColumns,
      field: 'ownerGroup'
    },
    { colId: 'numberOfBytes', headerName: 'バイト数', field: 'numberOfBytes' },
    { colId: 'wordCount', headerName: 'ワード数', field: 'wordCount' },
    { colId: 'weightedWordCount', headerName: '加重ワード数', field: 'weightedWordCount' },
    { colId: 'calculatedAmount', headerName: '算定金額', field: 'calculatedAmount' },
    {
      colId: 'orderedAt',
      headerName: '発注日',
      field: 'orederedAt',
      width: 150,
      valueFormatter: DateTimeFormatter
    },
    {
      colId: 'dueAt',
      headerName: '希望完了日時',
      field: 'dueAt',
      width: 150,
      valueFormatter: DateTimeFormatter
    },
    {
      colId: 'startReviewAt',
      headerName: 'レビュー予定日',
      field: 'startReviewAt',
      width: 150,
      valueFormatter: DateTimeFormatter
    },
    {
      colId: 'reviewDueAt',
      headerName: 'レビューFB予定日',
      field: 'reviewDueAt',
      width: 150,
      valueFormatter: DateTimeFormatter
    },
    {
      colId: 'completedAt',
      headerName: '実際完了日時',
      field: 'completedAt',
      width: 150,
      valueFormatter: DateTimeFormatter
    },
    {
      colId: 'createdAt',
      headerName: '作成日時',
      field: 'createdAt',
      width: 150,
      valueFormatter: DateTimeFormatter
    },
    {
      colId: 'updatedAt',
      headerName: '更新日時',
      field: 'updatedAt',
      width: 150,
      valueFormatter: DateTimeFormatter
    },
    {
      colId: 'archivedAt',
      headerName: 'アーカイブ日時',
      field: 'archivedAt',
      width: 150,
      valueFormatter: DateTimeFormatter
    }
  ]

  return { columnDefs, defaultColDef }
}

export default useColumnDefs
