import React from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { BackendError } from '../../../lib/BackendError'
import Project from '../../../lib/Project'
import { OverlayTriggerButton } from '../../common/OverlayTriggerButton'
import { useQuotedAmountDialog, Action, State } from './useQuotedAmountDialog'

type ContainerProps = {
  close: (updated: boolean) => void
  project: Project
}

type Props = ContainerProps & {
  state: State
  dispatch: React.Dispatch<Action>
  onKeyPress: (e: React.KeyboardEvent<HTMLFormElement>) => void
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
}

export const Component: React.FC<Props> = (props: Props) => {
  const { close, state, dispatch, onKeyPress, handleSubmit } = props

  const { t } = useTranslation()

  return (
    <Modal onHide={(): void => close(false)} show={true} size="lg">
      <Form onKeyPress={onKeyPress} onSubmit={handleSubmit}>
        <Modal.Header closeButton>{t('見積金額入力')}</Modal.Header>
        <Modal.Body>
          <Form.Group as={Row} controlId="quotedAmount">
            <Form.Label column sm={4}>
              {t('見積金額')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                className="formTableCell"
                disabled={state.loading}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  dispatch({
                    type: 'SET_FORM_VALUE',
                    payload: {
                      quotedAmount: parseInt(e.currentTarget.value)
                    }
                  })
                }
                type="number"
                data-testid="quoted-amount"
              />
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <span style={state.message.isError ? { color: 'red' } : undefined}>
            {state.message.text}
          </span>
          <OverlayTriggerButton
            tooltipLabel={t('処理中')}
            buttonLabel="OK"
            isOverlayed={(): boolean => state.loading}
            isLoading={(): boolean => state.loading}
            variant="primary"
            tooltipId="tooltip-updating-quoted-amount-in-progress"
            type="submit"
            data-testid="submit-quoted-amount-button"
          />
          <Button
            disabled={state.loading}
            onClick={(): void => props.close(false)}
            variant="secondary"
          >
            {t('キャンセル')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { close, project } = props
  const { state, dispatch, handleSubmit: updateQuotedAmount } = useQuotedAmountDialog(project)

  const onKeyPress = (e: React.KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === 'Enter' /* Enter */) {
      e.preventDefault()
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    try {
      await updateQuotedAmount()
      close(true)
    } catch (error) {
      if (error.response) {
        const errorInfo: BackendError = error.response.data
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: errorInfo.message, isError: true }
        })
      } else {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: error.message, isError: true }
        })
      }
    } finally {
      dispatch({ type: 'SET_IS_LOADING', payload: { loading: false } })
    }
  }

  return (
    <Component
      {...props}
      close={close}
      project={project}
      state={state}
      dispatch={dispatch}
      onKeyPress={onKeyPress}
      handleSubmit={handleSubmit}
    />
  )
}

Container.displayName = 'QuotedAmountDialog'
export default Container
