import { useCallback, useEffect, useReducer } from 'react'
import axios from 'axios'
import queryString from 'query-string'

import config from '../../../config'
import Job from '../../../lib/Job'
import { useTranslation } from 'react-i18next'

type Filters = {
  projectId: string
  srcLang?: string
  tgtLang?: string
  '[properties.anken_id][$regex]'?: string
}

export type State = {
  loading: boolean
  message: {
    isError: boolean
    text: string
  }
  subjectId: string
  options: string
  selectedJobs: Job[]
}

export type Action =
  | { type: 'SET_IS_LOADING'; payload: { loading: boolean } }
  | { type: 'SET_MESSAGE'; payload: { message: string; isError?: boolean } }
  | { type: 'SET_SUBJECT_ID'; payload: { subjectId: string } }
  | { type: 'SET_OPTIONS'; payload: { options: string } }
  | { type: 'SET_SELECTED_JOBS'; payload: { selectedJobs: Job[] } }

export const initialState: State = {
  loading: false,
  message: {
    isError: false,
    text: ''
  },
  subjectId: '',
  options: '',
  selectedJobs: []
}

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_IS_LOADING':
      return {
        ...state,
        loading: action.payload.loading
      }
    case 'SET_MESSAGE':
      return {
        ...state,
        message: {
          text: action.payload.message,
          isError: action.payload.isError ?? false
        }
      }
    case 'SET_SUBJECT_ID':
      return { ...state, subjectId: action.payload.subjectId }
    case 'SET_OPTIONS':
      return { ...state, options: action.payload.options }
    case 'SET_SELECTED_JOBS':
      return { ...state, selectedJobs: action.payload.selectedJobs }
    default:
      return state
  }
}

const useSelectJobsDialog = (
  projectId: string,
  srcLang?: string,
  tgtLang?: string
): {
  state: State
  dispatch: React.Dispatch<Action>
  handleSubmit: () => Promise<void>
  applyFilters: () => void
  clearFilters: () => void
  updateSelection: (selected: Job[]) => void
  selectedJobsIncludeInitializingJobs: () => boolean
} => {
  const [state, dispatch] = useReducer(stateReducer, initialState)
  const { t } = useTranslation()

  useEffect(() => {
    const filters: Filters = {
      projectId: 'null'
    }

    if (srcLang) {
      filters.srcLang = srcLang
    }

    if (tgtLang) {
      filters.tgtLang = tgtLang
    }

    dispatch({ type: 'SET_OPTIONS', payload: { options: queryString.stringify(filters) } })
  }, [srcLang, tgtLang])

  const handleSubmit = useCallback(async () => {
    dispatch({ type: 'SET_IS_LOADING', payload: { loading: true } })
    dispatch({
      type: 'SET_MESSAGE',
      payload: { message: t('ジョブを束ねています'), isError: false }
    })

    await Promise.all(
      state.selectedJobs.map((job: Job) => {
        return axios.patch(`${config[config.STAGE].endpoint}/api/v1/jobs/${job._id}`, { projectId })
      })
    )
  }, [projectId, state.selectedJobs, t])

  const applyFilters = (): void => {
    const filters: Filters = {
      projectId: 'null'
    }

    if (srcLang) {
      filters.srcLang = srcLang
    }

    if (tgtLang) {
      filters.tgtLang = tgtLang
    }

    if (state.subjectId !== '') {
      filters['[properties.anken_id][$regex]'] = `^${state.subjectId}`
    }
    dispatch({ type: 'SET_OPTIONS', payload: { options: queryString.stringify(filters) } })
  }

  const clearFilters = (): void => {
    const filters: Filters = {
      projectId: 'null'
    }

    if (srcLang) {
      filters.srcLang = srcLang
    }

    if (tgtLang) {
      filters.tgtLang = tgtLang
    }

    dispatch({ type: 'SET_SUBJECT_ID', payload: { subjectId: '' } })
    dispatch({ type: 'SET_OPTIONS', payload: { options: queryString.stringify(filters) } })
  }

  const updateSelection = (selected: Job[]): void => {
    dispatch({
      type: 'SET_SELECTED_JOBS',
      payload: { selectedJobs: selected }
    })
  }

  const selectedJobsIncludeInitializingJobs = (): boolean => {
    const statuses = state.selectedJobs.map(job => {
      return job.status
    })

    return statuses.indexOf('initializing') !== -1 ? true : false
  }

  return {
    state,
    dispatch,
    handleSubmit,
    applyFilters,
    clearFilters,
    updateSelection,
    selectedJobsIncludeInitializingJobs
  }
}

export default useSelectJobsDialog
