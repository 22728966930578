import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Breadcrumb } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { LinkContainer } from 'react-router-bootstrap'

import Job from '../../lib/Job'
import { OrganizationType } from '../../lib/Utils'

import { useLoginUser } from '../../hooks/useLoginUser'
import { ProjectDetailPageProvider } from './useProjectDetailPage'
import { useRecalculate } from '../../hooks/useRecalculate'

import Dialogs from './Dialogs'
import ProjectDetailCard from './ProjectDetailCard'
import Toolbar from './Toolbar'
import JobList from '../../components/JobList'
import { CenteredSpinner } from '../../components/common/CenteredSpinner'
import config from '../../config'

interface Props extends RouteComponentProps<{ id: string }> {
  className?: string
}

const ProjectDetail: React.FC<Props> = (props: Props) => {
  const projectId = props.match.params.id
  const { t } = useTranslation()

  const loginUser = useLoginUser()

  const [toggleRefresh, setToggleRefresh] = useState(true)
  const { project, loading } = useRecalculate({ projectId, toggleRefresh })

  const [selectedJobs, setSelectedJobs] = useState<Job[]>([])

  const refreshJobList = (): void => {
    setToggleRefresh(!toggleRefresh)
    setSelectedJobs([])
  }

  const updateSelection = (selected: Job[]): void => {
    setSelectedJobs(selected)
  }

  const isVisible = (): boolean => {
    if (!loginUser || !project) {
      return false
    }
    if (loginUser.organizationType === OrganizationType.CISTATE) {
      return true
    }
    if (
      loginUser.organizationType === OrganizationType.OWNER_GROUP &&
      project.ownerGroupId &&
      loginUser.organizationIds?.includes(project.ownerGroupId)
    ) {
      return true
    }
    if (
      loginUser.organizationType === OrganizationType.VENDOR &&
      project.vendorId &&
      loginUser.organizationIds?.includes(project.vendorId)
    ) {
      return true
    }

    return false
  }

  const isEditorVisible = (): boolean => {
    if (!loginUser) return false
    // vendorユーザー以外にエディタボタンを表示
    return loginUser.organizationType !== OrganizationType.VENDOR
  }

  // projectの読み込みが完了していない段階では子コンポーネントを描画せず Spinner を表示させる
  if (!project || loading) {
    return <CenteredSpinner />
  }

  return (
    <ProjectDetailPageProvider>
      <div className="Page">
        {isVisible() && (
          <>
            <Breadcrumb>
              <LinkContainer to="/projects">
                <Breadcrumb.Item>{t('プロジェクト一覧')}</Breadcrumb.Item>
              </LinkContainer>
              <Breadcrumb.Item active>{project.name}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="ml-3 mr-3 mb-3">
              <h2>{project.name}</h2>
              <ProjectDetailCard project={project} refreshJobList={refreshJobList} />
            </div>
            <Toolbar project={project} selectedJobs={selectedJobs} />
            <JobList
              options={'projectId=' + projectId}
              showDownloadButtonColumn={true}
              showOpenEditorButtonColumn={isEditorVisible()}
              showProjectInfoColumns={false}
              updateSelection={updateSelection}
              toggleRefresh={toggleRefresh}
            />
            <Dialogs
              project={project}
              refreshJobList={refreshJobList}
              selectedJobs={selectedJobs}
            />
          </>
        )}
      </div>
    </ProjectDetailPageProvider>
  )
}

export default ProjectDetail
