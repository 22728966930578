import React, { useEffect } from 'react'
import { Button, ButtonToolbar, Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import useAxios from 'axios-hooks'
import queryString from 'query-string'
import Qs from 'qs'

import config from '../../../config'
import Job from '../../../lib/Job'
import { useLoginUser } from '../../../hooks/useLoginUser'
import Project from '../../../lib/Project'
import { BatchStatus } from '../../../lib/Utils'
import { useBatchStatus } from '../../../hooks/useBatchStatus'
import ToggleSortOrderButton from '../../../components/ToggleSortOrderButton'
import { useProjectDetailPage } from '../useProjectDetailPage'
import ActionsRow from './ActionsRow'
import useQueryString from '../../../hooks/useQueryString'

interface Props extends RouteComponentProps {
  project: Project
  selectedJobs: Job[]
  // canCreateTranslationKit: boolean | null
  // setCanCreateTranslationKit: Function
}

const Toolbar: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  const loginUser = useLoginUser()

  const { state, dispatch } = useProjectDetailPage()

  const { project } = props

  // 翻訳キット生成のバッチステータスをポーリングで取得
  const { batchStatus } = useBatchStatus({
    resourceId: project._id,
    batchName: 'create-translation-kit',
    polling: true
  })

  const [{ data: countData }, countRefetch] = useAxios({
    url: config[config.STAGE].endpoint + '/api/v1/jobs/count',
    params: {
      ...Qs.parse(props.location.search.substr(1)),
      projectId: props.project._id
    }
  })
  const [{ data: totalData }, totalRefetch] = useAxios({
    url: config[config.STAGE].endpoint + '/api/v1/jobs/count',
    params: { projectId: props.project._id }
  })

  const { sortBy, orderByAsc } = useQueryString(props.location.search)

  useEffect(() => {
    countRefetch()
    totalRefetch()
    // - projectにjobが存在しない場合は null を設定
    // - projectにjobが存在し、かつAWS Batchのステータスが下記の場合は生成可能(true)
    // -- 空文字 (過去に実行した履歴がない)
    // -- SUCCEEDED/FAILED (成功また失敗済み)
    // - それ以外はBatchが実行中なので生成不可(false)
    if (project.statistics && project.statistics.jobsCount === 0) {
      dispatch({
        type: 'UPDATE',
        payload: {
          ...state,
          canCreateTranslationKit: null
        }
      })
    } else if (
      [BatchStatus.NONE, BatchStatus.SUCCEEDED, BatchStatus.FAILED].includes(batchStatus)
    ) {
      dispatch({
        type: 'UPDATE',
        payload: {
          ...state,
          canCreateTranslationKit: true
        }
      })
    } else {
      dispatch({
        type: 'UPDATE',
        payload: {
          ...state,
          canCreateTranslationKit: false
        }
      })
    }
  }, [props, project, batchStatus, props.location.search, countRefetch, totalRefetch])

  const clearFilters = (): void => {
    const qs = props.location.search
    const query = queryString.parse(qs)
    Object.keys(query).forEach((key: string) => {
      if (!key.startsWith('options[sort]')) {
        delete query[key]
      }
    })

    props.history.push(
      '/projects/' +
        props.project._id +
        (Object.keys(query).length > 0 ? '?' + queryString.stringify(query) : '')
    )
  }

  const onChangeSortBy = (value: string): void => {
    const qs = props.location.search
    const queries = queryString.parse(qs)
    delete queries[`options[sort][${sortBy}]`]
    queries[`options[sort][${value}]`] = orderByAsc ? '1' : '-1'

    const options = queryString.stringify(queries)
    props.history.push('/projects/' + props.project._id + '?' + options)
  }

  const toggleOrderByAsc = (): void => {
    const qs = props.location.search
    const queries = queryString.parse(qs)
    queries[`options[sort][${sortBy ? sortBy : 'properties.anken_id'}]`] = !orderByAsc ? '1' : '-1'
    const options = queryString.stringify(queries)
    props.history.push('/projects/' + props.project._id + '?' + options)
  }

  return (
    <div>
      <ActionsRow project={props.project} selectedJobs={props.selectedJobs} />
      <Container fluid>
        <ButtonToolbar className="mb-3">
          <Button className="ml-auto" disabled variant="link">
            {countData?.count} / {totalData?.count}
          </Button>
          <Button
            className="ml-2"
            onClick={(): void => {
              dispatch({
                type: 'UPDATE',
                payload: {
                  ...state,
                  showJobFiltersDialog: true
                }
              })
            }}
            variant="light"
          >
            {t('絞り込み')}
          </Button>
          <Button className="ml-2" onClick={clearFilters} variant="light">
            {t('絞り込み解除')}
          </Button>
          <select
            className="ml-2"
            onChange={(e: React.FormEvent<HTMLSelectElement>): void =>
              onChangeSortBy(e.currentTarget.value)
            }
            value={sortBy ? sortBy : 'properties.anken_id'}
          >
            <option value="properties.anken_id">{t('案件ID')}</option>
            <option value="status">{t('ステータス')}</option>
            <option value="wordCount">{t('ワード数')}</option>
            <option value="weightedWordCount">{t('加重ワード数')}</option>
          </select>
          <ToggleSortOrderButton
            onClick={toggleOrderByAsc}
            orderByAsc={orderByAsc ? orderByAsc : false}
          />
        </ButtonToolbar>
      </Container>
    </div>
  )
}

export default withRouter(Toolbar)
