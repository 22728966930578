import { createContext, useContext } from 'react'

import Vendor from '../lib/Vendor'

const VendorsContext = createContext<Vendor[] | null>(null)

export const VendorsProvider = VendorsContext.Provider

export const VendorsConsumer = VendorsContext.Consumer

export const useVendors = (): Vendor[] | null => {
  return useContext(VendorsContext)
}
