import React, { useState } from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import axios from 'axios'

import config from '../../config'
import Job from '../../lib/Job'
import Toolbar from './Toolbar'
import JobList from '../../components/JobList'
import EditJobsDialog from '../../components/dialogs/EditJobsDialog'
import JobFiltersDialog from '../../components/dialogs/JobFiltersDialog'
import RegisterProjectDialog from '../../components/dialogs/RegisterProjectDialog'
import RegisterJobsDialog from '../../components/dialogs/RegisterJobsDialog'
import OKCancelDialog from '../../components/dialogs/OKCancelDialog'

interface Props extends RouteComponentProps<{}> {
  className?: string
}

const Jobs: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  const [selectedJobs, setSelectedJobs] = useState<Job[]>([])

  const [showEditJobsDialog, setShowEditJobsDialog] = useState(false)
  const [showJobFiltersDialog, setShowJobFiltersDialog] = useState(false)
  const [showRegisterProjectDialog, setShowRegisterProjectDialog] = useState(false)
  const [showRegisterJobsDialog, setShowRegisterJobsDialog] = useState(false)
  const [showDeleteJobsDialog, setShowDeleteJobsDialog] = useState(false)
  const [refreshJobList, setRefreshJobList] = useState(true)

  const updateSelection = (selected: Job[]): void => {
    setSelectedJobs(selected)
  }

  const deleteJobs = async (): Promise<void> => {
    await Promise.all(
      selectedJobs.map(job => {
        return axios.delete(config[config.STAGE].endpoint + '/api/v1/jobs/' + job._id)
      })
    )
  }

  return (
    <div className="Page">
      <Breadcrumb>
        <Breadcrumb.Item active data-testid="breadcrumb-job-list">
          {t('ジョブ一覧')}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Toolbar
        selectedJobs={selectedJobs}
        showRegisterProjectDialog={(): void => setShowRegisterProjectDialog(true)}
        showJobfiltersDialog={(): void => setShowJobFiltersDialog(true)}
        showEditJobsDialog={(): void => setShowEditJobsDialog(true)}
        showRegisterJobsDialog={(): void => setShowRegisterJobsDialog(true)}
        showDeleteJobsDialog={(): void => setShowDeleteJobsDialog(true)}
      />
      <JobList
        showDownloadButtonColumn={true}
        showProjectInfoColumns={true}
        updateSelection={updateSelection}
        toggleRefresh={refreshJobList}
      />
      {showRegisterProjectDialog && (
        <RegisterProjectDialog
          close={(): void => setShowRegisterProjectDialog(false)}
          jobs={selectedJobs}
          title={t('プロジェクト登録')}
        />
      )}
      {showRegisterJobsDialog && (
        <RegisterJobsDialog
          close={(updated: boolean): void => {
            setShowRegisterJobsDialog(false)
            if (updated) {
              setRefreshJobList(!refreshJobList)
              setSelectedJobs([])
            }
          }}
          title={t('ジョブ登録')}
        />
      )}
      {showEditJobsDialog && (
        <EditJobsDialog
          close={(updated: boolean): void => {
            setShowEditJobsDialog(false)
            if (updated) {
              setRefreshJobList(!refreshJobList)
              setSelectedJobs([])
            }
          }}
          jobs={selectedJobs}
        />
      )}
      {showDeleteJobsDialog && (
        <OKCancelDialog
          body={
            <>
              <p>{t('以下のジョブを削除しますか？')}</p>
              <ul>
                {selectedJobs.map(job => {
                  return <li key="job._id">{job.name}</li>
                })}
              </ul>
            </>
          }
          buttonVariant="danger"
          close={(updated: boolean): void => {
            setShowDeleteJobsDialog(false)
            if (updated) {
              setRefreshJobList(!refreshJobList)
              setSelectedJobs([])
            }
          }}
          onClickOK={deleteJobs}
          title=""
        />
      )}
      {showJobFiltersDialog && (
        <JobFiltersDialog close={(): void => setShowJobFiltersDialog(false)} />
      )}
    </div>
  )
}

export default Jobs
