import OwnerGroup from './OwnerGroup'
import Status from './Status'
import User from './User'
import Vendor from './Vendor'

type Project = {
  _id: string
  name: string
  repositoryFolderId?: string
  status?: Status
  statistics?: { [key: string]: number }
  numberOfBytes?: number
  wordCount?: number
  weightedWordCount?: number
  unitPrice?: number
  currency?: string
  calculatedAmount?: number
  quotedAmount?: number
  orderAmount?: number
  projectType?: string
  srcLang?: string
  tgtLang?: string
  dueAt?: Date
  completedAt?: Date
  vendorId?: string
  ownerId?: string
  ownerGroupId?: string
  createdAt?: Date
  updatedAt?: Date
  vendor?: Vendor
  ownerGroup?: OwnerGroup
  owner?: User
  approvedAt?: Date
  approvedBy?: string
  cancelledAt?: Date
  cancelledBy?: string
  archivedAt?: Date
  mdmJobsCount?: number
  unitsPerXliff?: number
}

export const getChildResourceName = (
  project: Project,
  field: 'vendor' | 'owner' | 'ownerGroup'
): string => {
  if (!project[field]) {
    return ''
  }
  if (field === 'owner') {
    const owner = project[field]
    return owner ? owner.username : ''
  }
  const vendorOrOwnerGroup = project[field]
  return vendorOrOwnerGroup ? vendorOrOwnerGroup.name : ''
}

export default Project
