import React from 'react'
import { ICellRendererParams } from 'ag-grid-community'

import { useVendors } from '../../../hooks/useVendors'
import { lookupValue } from '../../../lib/Utils'

const VendorCellRenderer = (params: ICellRendererParams): JSX.Element => {
  const vendors = useVendors()

  const vendorMappings = vendors?.reduce((acc, val) => {
    const result: { [key: string]: string } = acc
    result[val._id] = `${val.name} (${val.srcLang} -> ${val.tgtLang})`
    return result
  }, {})

  return (
    <>
      <span>{vendorMappings ? lookupValue(vendorMappings, params.value) : params.value}</span>
    </>
  )
}

export default VendorCellRenderer
