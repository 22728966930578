/* eslint-disable import/first */
import i18next from 'i18next'
import { ValueFormatterParams } from '@ag-grid-community/core'
import path from 'path'

import enTranslation from '../locales/en.json'

i18next.init({
  lng: 'en',
  debug: true,
  resources: {
    en: {
      translation: enTranslation
    }
  }
})

import dateformat from 'dateformat'

import { statusCodeToStatusName } from './Status'

import {
  contentTypeCodeToContentTypeName,
  jobTypeCodeToJobTypeName,
  langCodeToLangName,
  projectTypeCodeToProjectTypeName,
  userRoleCodeToUserRoleName
} from './Utils'

export const ContentTypeFormatter = (params: ValueFormatterParams): string => {
  return contentTypeCodeToContentTypeName(params.value)
}

export const DateTimeFormatter = (params: ValueFormatterParams): string => {
  if (typeof params.value === 'string') {
    return dateformat(Date.parse(params.value), 'yyyy-mm-dd HH:MM:ss')
  }
  return ''
}

export const EmptyCellFormatter = (params: ValueFormatterParams): string => {
  return ''
}

export const JobNameFormatter = (params: ValueFormatterParams): string => {
  return params.value ? path.basename(params.value) : ''
}

export const JobTypeFormatter = (params: ValueFormatterParams): string => {
  return jobTypeCodeToJobTypeName(params.value)
}

export const LanguageNameFormatter = (params: ValueFormatterParams): string => {
  return langCodeToLangName(params.value)
}

export const ProjectTypeFormatter = (params: ValueFormatterParams): string => {
  return projectTypeCodeToProjectTypeName(params.value)
}

export const StatusNameFormatter = (params: ValueFormatterParams): string => {
  return statusCodeToStatusName(params.value)
}

export const OrganizationTypeFormatter = (params: ValueFormatterParams): string => {
  switch (params.value) {
    case 'owner-group':
      return 'MISUMI'
    case 'vendor':
      return 'Vendor'
    case 'cistate':
      return 'Cistate'
    default:
      return params.value
  }
}

export const IsEnabledFormatter = (params: ValueFormatterParams): string => {
  if (params.value === true) {
    return i18next.t('有効')
  } else if (params.value === false) {
    return i18next.t('無効')
  }
  return ''
}

export const BooleanFormatter = (params: ValueFormatterParams): string => {
  if (params.value === true) {
    return '✔'
  }
  return ''
}

export const UserRoleFormatter = (params: ValueFormatterParams): string => {
  return userRoleCodeToUserRoleName(params.value)
}
