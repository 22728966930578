import Project, { getChildResourceName } from '../../lib/Project'

export type RowData = {
  _id: string
  name: string
  calculatedAmount?: number
  completedAt?: Date
  dueAt?: Date
  jobsCount?: number
  orderAmount?: number
  currency?: string
  status?: string
  owner?: string
  ownerGroup?: string
  projectType?: string
  srcLang?: string
  tgtLang?: string
  quotedAmount?: number
  vendor?: string
  wordCount?: number
  weightedWordCount?: number
  createdAt?: Date
  updatedAt?: Date
  archivedAt?: Date
  approvedAt?: Date
  cancelledAt?: Date
  mdmJobsCount?: number
}

export const ProjectToRowData = (project: Project): RowData => {
  return {
    _id: project._id,
    name: project.name,
    jobsCount: project.statistics ? project.statistics.jobsCount : undefined,
    wordCount: project.wordCount,
    weightedWordCount: project.weightedWordCount,
    calculatedAmount: project.calculatedAmount,
    quotedAmount: project.quotedAmount,
    orderAmount: project.orderAmount,
    currency: project.vendor?.currency,
    status: project.status,
    projectType: project.projectType,
    srcLang: project.srcLang,
    tgtLang: project.tgtLang,
    vendor:
      getChildResourceName(project, 'vendor') +
      (project.vendor ? ` (${project.vendor?.srcLang} -> ${project.vendor?.tgtLang})` : ''),
    owner: getChildResourceName(project, 'owner'),
    ownerGroup: getChildResourceName(project, 'ownerGroup'),
    createdAt: project.createdAt,
    updatedAt: project.updatedAt,
    archivedAt: project.archivedAt,
    approvedAt: project.approvedAt,
    cancelledAt: project.cancelledAt,
    mdmJobsCount: project.mdmJobsCount
  }
}

export default RowData
