import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { useFilters } from './useFilters'

const BasicConditions: React.FC = () => {
  const { t } = useTranslation()

  const { state, dispatch } = useFilters()

  const langOptions = (
    <>
      <option value="">({t('指定なし')})</option>
      <option value="de-DE">{t('German')}</option>
      <option value="en-US">{t('English (US)')}</option>
      <option value="es-MX">{t('Spanish (Mexico)')}</option>
      <option value="fr-FR">{t('French')}</option>
      <option value="it-IT">{t('Italian')}</option>
      <option value="ja-JP">{t('Japanese')}</option>
      <option value="ko-KR">{t('Korean')}</option>
      <option value="zh-CN">{t('Chinese (Simplified)')}</option>
      <option value="zh-TW">{t('Chinese (Taiwan)')}</option>
      <option value="th-TH">{t('Thai')}</option>
    </>
  )

  return (
    <>
      <Row>
        <Col sm={2}>
          <Form.Group>
            <Form.Label>{t('ソース言語')}:</Form.Label>
            <Form.Control
              as="select"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                dispatch({
                  type: 'UPDATE',
                  payload: {
                    ...state,
                    srcLang: e.currentTarget.value
                  }
                })
              }}
              value={state.srcLang}
            >
              {langOptions}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group>
            <Form.Label>{t('原文')}:</Form.Label>
            <Form.Control
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                dispatch({
                  type: 'UPDATE',
                  payload: {
                    ...state,
                    srcTmx: e.currentTarget.value
                  }
                })
              }
              value={state.srcTmx}
            />
            <Form.Text>{t('部分一致')}</Form.Text>
          </Form.Group>
        </Col>
        <Col sm={2}>
          <Form.Group>
            <Form.Label>{t('ターゲット言語')}:</Form.Label>
            <Form.Control
              as="select"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                dispatch({
                  type: 'UPDATE',
                  payload: {
                    ...state,
                    tgtLang: e.currentTarget.value
                  }
                })
              }}
              value={state.tgtLang}
            >
              {langOptions}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group>
            <Form.Label>{t('訳文')}:</Form.Label>
            <Form.Control
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                dispatch({
                  type: 'UPDATE',
                  payload: {
                    ...state,
                    tgtTmx: e.currentTarget.value
                  }
                })
              }
              value={state.tgtTmx}
            />
            <Form.Text>{t('部分一致')}</Form.Text>
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}

export default BasicConditions
