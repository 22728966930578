import React, { useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import queryString from 'query-string'

import config from '../../../config'
import { OverlayTriggerButton } from '../../../components/common/OverlayTriggerButton'

interface Props extends RouteComponentProps {}

const ExportCsvButton: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)

  const exportCsv = (): void => {
    setIsLoading(true)
    const qs = props.location.search
    const query = queryString.parse(qs)

    const defaultSort =
      Object.keys(query).filter(key => {
        return key.startsWith('options')
      }).length > 0
        ? false
        : true

    if (defaultSort) {
      query['options[sort][createdAt]'] = '-1'
    }

    axios
      .get(config[config.STAGE].endpoint + '/api/v1/jobs/download?' + queryString.stringify(query))
      .then(res => {
        window.open(res.data.presignedUrl, '_blank')
        setIsLoading(false)
      })
  }

  return (
    <Button className="ml-auto" disabled={isLoading} onClick={exportCsv} variant="secondary">
      {isLoading && (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          className="mr-1"
        />
      )}
      {t('CSV')} <FontAwesomeIcon icon={faArrowDown} />
    </Button>
  )
}

export default withRouter(ExportCsvButton)
