import { createContext, useContext } from 'react'

import OwnerGroup from '../lib/OwnerGroup'

const OwnerGroupsContext = createContext<OwnerGroup[] | null>(null)

export const OwnerGroupsProvider = OwnerGroupsContext.Provider

export const OwnerGroupsConsumer = OwnerGroupsContext.Consumer

export const useOwnerGroups = (): OwnerGroup[] | null => {
  return useContext(OwnerGroupsContext)
}
