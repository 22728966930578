import React from 'react'
import { Alert, Button, Form, Modal, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { OverlayTriggerButton } from '../../common/OverlayTriggerButton'
import { BackendError } from '../../../lib/BackendError'
import { useTranslationKitDownloadUrl } from '../../../hooks/useDownloadUrl'
import Project from '../../../lib/Project'
import { useCreateTranslationKitDialog, State, Action } from './useCreateTranslationKitDialog'

type ContainerProps = {
  close: (creating: boolean) => void
  project: Project
}

type Props = ContainerProps & {
  state: State
  dispatch: React.Dispatch<Action>
  onKeyPress: (e: React.KeyboardEvent<HTMLFormElement>) => void
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
}

export const Component: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { project } = props
  const { close, state, dispatch, onKeyPress, handleSubmit } = props

  const { objectExists } = useTranslationKitDownloadUrl({
    project,
    polling: false
  })

  const closeOnCancel = (): void => {
    close(false)
  }

  return (
    <Modal onHide={closeOnCancel} show={true} size="lg">
      <Form onSubmit={handleSubmit} onKeyPress={onKeyPress}>
        <Modal.Header closeButton>{t('翻訳キットを生成')}</Modal.Header>
        <Modal.Body>
          {objectExists && (
            <Alert variant="warning" data-testid="translation-kit-already-exists-warning">
              {t(
                '【注意】XLIFF ファイルが再生成されます。過去に生成した XLIFF ファイルは無効になり、その翻訳済み XLIFF ファイルをアップロードしても翻訳が正常に反映されないおそれがあります。'
              )}
            </Alert>
          )}
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              {t('1 XLIFF に含める翻訳単位の数')}:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                disabled={state.loading}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  if (e.currentTarget.value) {
                    dispatch({
                      type: 'SET_FORM_VALUE',
                      payload: {
                        unitsPerXliff: parseInt(e.currentTarget.value)
                      }
                    })
                  }
                }}
                type="number"
                min="100"
                max="1000"
                step="100"
                aria-describedby="unitsPerXliffHelp"
                value={state.formData.unitsPerXliff}
                data-testid="input-units-per-xliff"
              />
              <Form.Text id="unitsPerXliffHelp" muted>
                {t('100〜1000の値を入力')}
              </Form.Text>
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {state.message.isError ? (
            <span style={{ color: 'red' }}>{state.message.text}</span>
          ) : (
            <span>{state.message.text}</span>
          )}
          <OverlayTriggerButton
            tooltipLabel={t('処理中')}
            buttonLabel={t('OK')}
            isOverlayed={(): boolean => state.loading}
            isLoading={(): boolean => state.loading}
            variant="primary"
            tooltipId="tooltip-creating-translation-kit"
            type="submit"
            data-testid="create-translation-kit-button"
          />
          <Button
            disabled={state.loading}
            onClick={closeOnCancel}
            style={state.loading ? { pointerEvents: 'none' } : {}}
            variant="secondary"
          >
            {t('キャンセル')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { close, project } = props
  const { state, dispatch, handleSubmit: createTranslationKit } = useCreateTranslationKitDialog(
    project
  )

  const onKeyPress = (e: React.KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === 'Enter' /* Enter */) {
      e.preventDefault()
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    try {
      dispatch({ type: 'SET_MESSAGE', payload: { message: '翻訳キットを生成しています' } })
      await createTranslationKit()
      close(true)
    } catch (error) {
      if (error.response) {
        const errorInfo: BackendError = error.response.data
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: errorInfo.message, isError: true }
        })
      } else {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: error.message, isError: true }
        })
      }
    } finally {
      dispatch({ type: 'SET_IS_LOADING', payload: { loading: false } })
    }
  }

  return (
    <Component
      {...props}
      close={close}
      project={project}
      state={state}
      dispatch={dispatch}
      onKeyPress={onKeyPress}
      handleSubmit={handleSubmit}
    />
  )
}

Container.displayName = 'CreateTranslationKitDialog'
export default Container
