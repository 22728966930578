import React from 'react'
import { Button, Form, Modal, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import Project from '../../../lib/Project'
import { OverlayTriggerButton } from '../../common/OverlayTriggerButton'
import { BackendError } from '../../../lib/BackendError'
import useEditProjectDialog, { State, Action, UNCHANGED } from './useEditProjectDialog'

type ContainerProps = {
  close: (updated: boolean) => void
  projects: Project[]
}

type Props = ContainerProps & {
  state: State
  dispatch: React.Dispatch<Action>
  onKeyPress: (e: React.KeyboardEvent<HTMLFormElement>) => void
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
}

export const Component: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { state, dispatch, onKeyPress, handleSubmit } = props

  const canExecute = (): boolean => state.formData.status !== UNCHANGED
  let runButton
  if (canExecute()) {
    runButton = (
      <OverlayTriggerButton
        tooltipLabel={t('更新中')}
        buttonLabel={t('Button_完了')}
        isOverlayed={(): boolean => state.loading}
        isLoading={(): boolean => state.loading}
        variant="primary"
        tooltipId="tooltip-editing-projects-in-progress"
        type="submit"
        data-testid="edit-projects-button"
      />
    )
  } else {
    runButton = (
      <OverlayTriggerButton
        tooltipLabel={t('少なくとも1つのフィールドに値を入力してください')}
        buttonLabel={t('Button_完了')}
        isOverlayed={(): boolean => true}
        variant="primary"
        tooltipId="tooltip-cannot-execute"
        data-testid="edit-projects-button"
      />
    )
  }

  return (
    <Modal
      onHide={(): void => props.close(false)}
      show={true}
      size="lg"
      data-testid="edit-projects-dialog"
    >
      <Form onKeyPress={onKeyPress} onSubmit={handleSubmit}>
        <Modal.Header closeButton>{t('プロジェクト編集')}</Modal.Header>
        <Modal.Body>
          <Form.Group as={Row} controlId="projects">
            <Form.Label column sm={4}>
              {t('編集するプロジェクト')} ({props.projects.length}):
            </Form.Label>
            <Col sm={8}>
              <Form.Control readOnly as="select" multiple data-testid="selected-projects-list">
                {props.projects.map(project => (
                  <option key={project._id}>{project.name}</option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="status">
            <Form.Label column sm={4}>
              {t('ステータス')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                value={state.formData.status}
                disabled={state.loading}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>): void =>
                  dispatch({
                    type: 'SET_FORM_VALUE',
                    payload: {
                      status: e.currentTarget.value
                    }
                  })
                }
                data-testid="select-status"
              >
                <option value={UNCHANGED}>{t('(変更なし)')}</option>
                <option value="prepared">{t('準備処理完了')}</option>
                <option value="quote_requested">{t('見積依頼中')}</option>
                <option value="quote_ready">{t('見積提出済み')}</option>
                <option value="order_confirmed">{t('発注確定')}</option>
                <option value="translated">{t('翻訳済み')}</option>
                <option value="reviewed">{t('レビュー済み')}</option>
                <option value="completed">{t('完了')}</option>
              </Form.Control>
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {state.message.isError ? (
            <span style={{ color: 'red' }}>{state.message.text}</span>
          ) : (
            <span>{state.message.text}</span>
          )}
          {runButton}
          <Button
            disabled={state.loading}
            onClick={(): void => props.close(false)}
            style={state.loading ? { pointerEvents: 'none' } : {}}
            variant="secondary"
          >
            {t('キャンセル')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { close, projects } = props
  const { state, dispatch, handleSubmit: updateProjects } = useEditProjectDialog(projects)

  const onKeyPress = (e: React.KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === 'Enter' /* Enter */) {
      e.preventDefault()
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    try {
      await updateProjects()
      close(true)
    } catch (error) {
      if (error.response) {
        const errorInfo: BackendError = error.response.data
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: errorInfo.message, isError: true }
        })
      } else {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: error.message, isError: true }
        })
      }
    } finally {
      dispatch({ type: 'SET_IS_LOADING', payload: { loading: false } })
    }
  }

  return (
    <Component
      {...props}
      close={close}
      projects={projects}
      state={state}
      dispatch={dispatch}
      onKeyPress={onKeyPress}
      handleSubmit={handleSubmit}
    />
  )
}

Container.displayName = 'EditProjectDialog'
export default Container
