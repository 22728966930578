import { useEffect, useState } from 'react'
import {
  BodyScrollEvent,
  CellEditingStoppedEvent,
  ColDef,
  ColumnResizedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent
} from '@ag-grid-community/core'
import axios from 'axios'
import queryString from 'query-string'

import useColumnDefs from './useColumnDefs'
import { RowData, TMEntryToRowData } from './RowData'
import config from '../../config'
import { useLoginUser } from '../../hooks/useLoginUser'
import TM from '../../lib/TM'
import CustomHeader from '../CustomHeader'
import {
  EditTMButtonRenderer,
  OwnerGroupCellRenderer,
  TMEntryCellRenderer,
  VendorCellRenderer
} from './CellRenderers'
import { ConfidenceCellEditor, TMEntryCellEditor, VendorCellEditor } from './CellEditors'
import { useOwnerGroups } from '../../hooks/useOwnerGroups'
import { useVendors } from '../../hooks/useVendors'
import { useTMEntriesPage } from '../../pages/TMEntries/useTMEntriesPage'

const useTMList = ({
  qs,
  toggleRefresh
}: {
  qs: string
  toggleRefresh?: boolean
}): {
  columnDefs: ColDef[]
  gridApi: GridApi | undefined
  gridOptions: GridOptions
  onBodyScroll: (e: BodyScrollEvent) => void
} => {
  const loginUser = useLoginUser()
  const ownerGroups = useOwnerGroups()
  const vendors = useVendors()

  const { state, dispatch } = useTMEntriesPage()

  const rowsPerPage = 100

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined)

  const [lastRow, setLastRow] = useState<number | null>(null)
  const [loading, setLoading] = useState(false)

  const { defaultColDef, columnDefs } = useColumnDefs()

  const query = queryString.parse(qs)
  const defaultSort =
    Object.keys(query).filter(key => {
      return key.startsWith('options')
    }).length > 0
      ? false
      : true

  const sort: {
    [key: string]: number
  } = {}
  if (defaultSort) {
    sort['createdAt'] = -1
  } else {
    const sortBy = Object.keys(query)
      .filter(key => {
        return key.startsWith('options[sort]')
      })
      .map(key => {
        return key.replace(/options\[sort\]\[([^\]]+)\]/, '$1')
      })
    sortBy.forEach(key => {
      if (Object.keys(sort).includes(key)) {
        const sortOrder = query[`options[sort][${key}]`]
        if (typeof sortOrder === 'string') {
          sort[key] = parseInt(sortOrder)
        }
      }
    })
  }

  const onGridReady = (params: GridReadyEvent): void => {
    setGridApi(params.api)

    if (localStorage.getItem('jobListColumnWidths') !== null) {
      const columnWidthsString = localStorage.getItem('jobListColumnWidths')
      if (typeof columnWidthsString === 'string') {
        const columnWidths = JSON.parse(columnWidthsString)
        Object.keys(columnWidths).forEach(key => {
          params.columnApi.setColumnWidth(key, columnWidths[key])
        })
      }
    }
  }

  const onColumnResized = (params: ColumnResizedEvent): void => {
    if (localStorage.getItem('TMListColumnWidths') !== null) {
      const columnWidthsString = localStorage.getItem('TMListColumnWidths')
      if (typeof columnWidthsString === 'string') {
        const columnWidths = JSON.parse(columnWidthsString)
        if (columnWidths) {
          const key = params.column?.getColDef().colId
          if (key !== undefined) {
            columnWidths[key] = params.column?.getActualWidth()
            localStorage.setItem('TMListColumnWidths', JSON.stringify(columnWidths))
          }
        }
      }
    } else {
      const key = params.column?.getColDef().colId
      if (key !== undefined) {
        const columnWidths: Record<string, any> = {}
        columnWidths[key] = params.column?.getActualWidth()
        localStorage.setItem('TMListColumnWidths', JSON.stringify(columnWidths))
      }
    }
  }

  const onCellEditingStopped = (params: CellEditingStoppedEvent): void => {
    const revert = (): void => {
      const colId = params.colDef.colId
      const data = params.data
      if (colId !== undefined) {
        data[colId] = params.oldValue
      }
      params.api.applyTransactionAsync(
        {
          update: [data]
        },
        (): void => {
          dispatch({
            type: 'UPDATE',
            payload: {
              ...state,
              showToast: true
            }
          })
        }
      )
    }
    if (params.colDef.colId === 'confidence') {
      switch (typeof params.newValue) {
        case 'number':
          if (params.newValue < 0 || params.newValue > 100) {
            revert()
            return
          }
          break
        case 'string':
          if (params.newValue.match(/^100$|^[0-9]{1,2}$/) === null) {
            revert()
            return
          }
          break
        default:
          break
      }
    }
    axios
      .patch(`${config[config.STAGE].endpoint}/api/v1/tmEntries/${params.data._id}`, {
        srcTmx: params.data.srcTmx,
        tgtTmx: params.data.tgtTmx,
        srcLang: params.data.srcLang,
        tgtLang: params.data.tgtLang,
        confidence: parseInt(params.data.confidence),
        ownerGroupId: params.data.ownerGroupId,
        vendorId: params.data.vendorId,
        properties: {
          category: params.data.category
        },
        active: params.data.active
      })
      .catch((): void => {
        revert()
      })
  }

  const onBodyScroll = async (params: BodyScrollEvent): Promise<void> => {
    if (lastRow !== -1 && loading === false) {
      const lastDisplayedRow = params.api.getLastDisplayedRow()
      if (lastRow && lastRow - 1 === lastDisplayedRow) {
        setLoading(true)
      }
    }
  }

  const gridOptions: GridOptions = {
    enableCellTextSelection: true,
    columnDefs: columnDefs,
    defaultColDef: defaultColDef,
    frameworkComponents: {
      agColumnHeader: CustomHeader,
      EditTMButtonRenderer: EditTMButtonRenderer,
      OwnerGroupCellRenderer: OwnerGroupCellRenderer,
      TMEntryCellEditor: TMEntryCellEditor,
      TMEntryCellRenderer: TMEntryCellRenderer,
      VendorCellEditor: VendorCellEditor,
      VendorCellRenderer: VendorCellRenderer,
      ConfidenceCellEditor: ConfidenceCellEditor
    },
    onCellEditingStopped: onCellEditingStopped,
    onColumnResized: onColumnResized,
    onGridReady: onGridReady,
    rowData: [undefined],
    rowSelection: 'multiple',
    tooltipShowDelay: 0
  }

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const query = queryString.parse(qs)
      const defaultSort =
        Object.keys(query).filter(key => {
          return key.startsWith('options')
        }).length > 0
          ? false
          : true

      const sort: {
        [key: string]: number
      } = {}
      if (defaultSort) {
        sort['createdAt'] = -1
      } else {
        const sortBy = Object.keys(query)
          .filter(key => {
            return key.startsWith('options[sort]')
          })
          .map(key => {
            return key.replace(/options\[sort\]\[([^\]]+)\]/, '$1')
          })
        sortBy.forEach(key => {
          if (Object.keys(sort).includes(key)) {
            const sortOrder = query[`options[sort][${key}]`]
            if (typeof sortOrder === 'string') {
              sort[key] = parseInt(sortOrder)
            }
          }
        })
      }

      const tmEntriesResponse = await axios.get(
        `${config[config.STAGE].endpoint}/api/v1/tmEntries`,
        {
          params: { ...query, options: { limit: rowsPerPage, sort } }
        }
      )
      gridApi?.setRowData(
        tmEntriesResponse.data.map(
          (tmEntry: TM): RowData => {
            return TMEntryToRowData(tmEntry, loginUser)
          }
        )
      )
      setLastRow(rowsPerPage)
    }

    if (gridApi) {
      gridApi?.setRowData([null])
      fetchData()
    }
  }, [gridApi, qs, loginUser, ownerGroups, vendors, toggleRefresh])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (gridApi && loading) {
        const transactionResult = gridApi.applyTransaction({
          add: [null],
          addIndex: lastRow
        })
        const loadingRowNode = transactionResult?.add[0]
        const tmEntriesResponse = await axios.get(
          `${config[config.STAGE].endpoint}/api/v1/tmEntries`,
          {
            params: {
              ...query,
              options: {
                limit: rowsPerPage,
                skip: lastRow,
                sort
              }
            }
          }
        )
        setLastRow(lastRow + tmEntriesResponse.data.length)
        gridApi.applyTransaction({ remove: [loadingRowNode?.data] })
        gridApi.applyTransaction({
          add: tmEntriesResponse.data.map(
            (tmEntry: TM): RowData => {
              return TMEntryToRowData(tmEntry, loginUser)
            }
          )
        })
        setLoading(false)
      }
    }
    fetchData()
  }, [gridApi, lastRow, loading, loginUser, query, sort])

  return { columnDefs, gridApi, gridOptions, onBodyScroll }
}

export default useTMList
