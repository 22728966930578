import React, { useState } from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import User from '../../../lib/User'
import Toolbar from './Toolbar'
import UserList from '../../../components/UserList'
import EditUserDialog from '../../../components/dialogs/EditUserDialog'

const Users: React.FC = () => {
  const { t } = useTranslation()

  const [selectedUsers, setSelectedUsers] = useState<User[]>([])
  const [refreshUserList, setRefreshUserList] = useState(true)
  const [showAddUserDialog, setShowAddUserDialog] = useState(false)

  const updateSelection = (selected: User[]): void => {
    setSelectedUsers(selected)
  }

  return (
    <div className="Page">
      <Breadcrumb>
        <Breadcrumb.Item active>{t('ユーザー一覧')}</Breadcrumb.Item>
      </Breadcrumb>
      <Toolbar
        selectedUsers={selectedUsers}
        showAddUserDialog={(): void => setShowAddUserDialog(true)}
      />
      <UserList updateSelection={updateSelection} toggleRefresh={refreshUserList} />
      {showAddUserDialog && (
        <EditUserDialog
          close={(updated: boolean): void => {
            setShowAddUserDialog(false)
            if (updated) {
              setRefreshUserList(!refreshUserList)
              setSelectedUsers([])
            }
          }}
        />
      )}
    </div>
  )
}

export default Users
