import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import i18next from 'i18next'
import { ICellEditorParams } from 'ag-grid-community'
import { useLoginUser } from '../../../hooks/useLoginUser'
import OwnerGroup from '../../../lib/OwnerGroup'

type Params = ICellEditorParams & {
  values?: any
}

const TMEntryCellEditor = forwardRef((params: Params, ref) => {
  const loginUser = useLoginUser()

  const [value, setValue] = useState(params.value)
  const [height, setHeight] = useState<number | undefined>(undefined)

  const onChange = (e: any): void => {
    setValue(e.currentTarget.value)
  }

  useEffect(() => {
    if (height === undefined) {
      setHeight(params.eGridCell.offsetHeight)
    }
  }, [height, params.eGridCell.offsetHeight])

  useImperativeHandle(ref, () => {
    return {
      getValue: (): string | boolean => {
        if (params.colDef.colId === 'active') {
          return value === 'true' ? true : false
        }
        return value
      }
    }
  })

  return (
    <>
      {params.data.editable ? (
        params.colDef.colId === 'srcTmx' || params.colDef.colId === 'tgtTmx' ? (
          <>
            {params.colDef.colId === 'tgtTmx' && params.value === '' && (
              <div
                style={{
                  backgroundColor: '#ccc',
                  border: '1px solid #000',
                  color: '#000',
                  position: 'absolute',
                  top: height !== undefined ? height : '',
                  left: '0px',
                  padding: '0 5px',
                  zIndex: 9999999
                }}
              >
                <FontAwesomeIcon icon={faExclamationTriangle} />
                {i18next.t('空文字列で登録します')}
              </div>
            )}
            <textarea
              onChange={onChange}
              style={{
                height: height !== undefined ? height : '',
                width: '100%'
              }}
              value={value}
              data-testid="tm-entry-cell-editor"
            />
          </>
        ) : params.colDef.cellEditorParams?.values ? (
          params.colDef.colId === 'ownerGroupId' ? (
            <select onChange={onChange} value={value}>
              {params.values
                .filter((ownerGroup: OwnerGroup) => {
                  if (loginUser && loginUser?.organizationType === 'cistate') {
                    return true
                  }
                  return loginUser
                    ? loginUser.organizationIds.includes(ownerGroup._id) ||
                        loginUser.organizationId === ownerGroup._id
                    : false
                })
                .map((ownerGroup: OwnerGroup) => {
                  return (
                    <option key={ownerGroup._id} value={ownerGroup._id}>
                      {params.formatValue(ownerGroup._id)}
                    </option>
                  )
                })}
            </select>
          ) : (
            <select onChange={onChange} value={value}>
              {params.values.map((value: any) => {
                return (
                  <option key={value} value={value}>
                    {params.formatValue(value)}
                  </option>
                )
              })}
            </select>
          )
        ) : (
          <div
            className="ag-cell-editor"
            style={{
              width: '100%'
            }}
          >
            <input
              onChange={onChange}
              style={{
                width: '100%'
              }}
              value={params.formatValue(value)}
            ></input>
          </div>
        )
      ) : (
        <span className="ag-cell-value" style={{ textOverflow: 'hidden' }}>
          {params.formatValue(value)}
        </span>
      )}
    </>
  )
})
TMEntryCellEditor.displayName = 'TMEntryCellEditor'

export default TMEntryCellEditor
