import { Dispatch, useCallback, useEffect, useReducer } from 'react'
import useAxios from 'axios-hooks'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

import config from '../../../config'
import Vendor from '../../../lib/Vendor'
import OwnerGroup from '../../../lib/OwnerGroup'
import { LoginUser } from '../../../hooks/useLoginUser'

type FormData = {
  srcLang: string
  tgtLang: string
  confidence: string
  ownerGroupId: string | null
  vendorId: string | null
  category: string
  selectedFiles: File[]
}

export type State = {
  loading: boolean
  vendors: Vendor[]
  ownerGroups: OwnerGroup[]
  message: {
    isError: boolean
    text: string
  }
  formData: FormData
}

export type Action =
  | { type: 'SET_IS_LOADING'; payload: { loading: boolean } }
  | { type: 'VENDORS_LOADED'; payload: { vendors: Vendor[] } }
  | { type: 'OWNER_GROUPS_LOADED'; payload: { ownerGroups: OwnerGroup[] } }
  | { type: 'SET_MESSAGE'; payload: { message: string; isError?: boolean } }
  | { type: 'SET_FORM_VALUE'; payload: Partial<FormData> }

export const initialState: State = {
  loading: false,
  message: {
    isError: false,
    text: ''
  },
  vendors: [],
  ownerGroups: [],
  formData: {
    srcLang: 'en-US',
    tgtLang: 'en-US',
    confidence: '100',
    ownerGroupId: null,
    vendorId: null,
    category: '',
    selectedFiles: []
  }
}

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_IS_LOADING': {
      return {
        ...state,
        loading: action.payload.loading
      }
    }
    case 'VENDORS_LOADED': {
      const { vendors } = action.payload
      return {
        ...state,
        loading: false,
        vendors,
        formData: {
          ...state.formData,
          vendorId: ''
        }
      }
    }
    case 'OWNER_GROUPS_LOADED': {
      const { ownerGroups } = action.payload
      return {
        ...state,
        loading: false,
        ownerGroups,
        formData: {
          ...state.formData,
          ownerGroupId: ownerGroups.length > 0 ? ownerGroups[0]._id : null
        }
      }
    }
    case 'SET_MESSAGE': {
      return {
        ...state,
        message: {
          text: action.payload.message,
          isError: action.payload.isError ?? false
        }
      }
    }
    case 'SET_FORM_VALUE': {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload
        }
      }
    }
    default: {
      return state
    }
  }
}

type UseImportTmxDialog = {
  (loginUser: LoginUser | null): {
    state: State
    dispatch: Dispatch<Action>
    handleSubmit: () => Promise<void>
  }
}

export const useImportTmxDialog: UseImportTmxDialog = loginUser => {
  const [state, dispatch] = useReducer(stateReducer, initialState)
  const { t } = useTranslation()

  const [, fetchOwnerGroups] = useAxios<OwnerGroup[]>(
    `${config[config.STAGE].endpoint}/api/v1/ownerGroups`,
    { manual: true }
  )
  const [, fetchVendors] = useAxios<Vendor[]>(`${config[config.STAGE].endpoint}/api/v1/vendors`, {
    manual: true
  })

  useEffect(() => {
    // loginUserがcistateの場合、全owernGroupを設定する
    // loginUserがowner-groupの場合、自身のownerGroupを設定する
    const loadOwnerGroups = async (): Promise<void> => {
      if (!loginUser) {
        return
      }
      if (loginUser.organizationType !== 'cistate') {
        const ownerGroups: OwnerGroup[] = loginUser.organizations as OwnerGroup[]
        dispatch({ type: 'OWNER_GROUPS_LOADED', payload: { ownerGroups } })
      } else {
        dispatch({ type: 'SET_IS_LOADING', payload: { loading: true } })
        const response = await fetchOwnerGroups()
        dispatch({ type: 'OWNER_GROUPS_LOADED', payload: { ownerGroups: response.data ?? [] } })
      }
    }
    loadOwnerGroups()
  }, [fetchOwnerGroups, loginUser])

  useEffect(() => {
    // 選択中のownerGroupに所属する翻訳会社を取得する
    if (!state.formData.ownerGroupId) {
      return
    }
    const loadVendors = async (): Promise<void> => {
      dispatch({ type: 'SET_IS_LOADING', payload: { loading: true } })
      const response = await fetchVendors({
        params: {
          isEnabled: true,
          ownerGroupId: state.formData.ownerGroupId
        }
      })
      dispatch({ type: 'VENDORS_LOADED', payload: { vendors: response.data ?? [] } })
    }
    loadVendors()
  }, [fetchVendors, state.formData.ownerGroupId])

  const handleSubmit = useCallback(async (): Promise<void> => {
    dispatch({ type: 'SET_IS_LOADING', payload: { loading: true } })
    dispatch({
      type: 'SET_MESSAGE',
      payload: { message: t('TMXファイルをアップロードしています') }
    })

    const { srcLang, tgtLang, confidence, ownerGroupId, vendorId, category } = state.formData

    await Promise.all(
      state.formData.selectedFiles.map(async file => {
        const uploadUrlResult = await axios.post<{
          presignedUrl: string
          objectKey: string
          contentType: string
        }>(`${config[config.STAGE].endpoint}/api/v1/generateUploadUrl`, {
          folder: 'dropzone/tmx',
          filename: file.name
        })
        await axios.put(uploadUrlResult.data.presignedUrl, file, {
          headers: {
            'Content-Type': uploadUrlResult.data.contentType
          }
        })

        return axios.post(`${config[config.STAGE].endpoint}/api/v1/tmEntries/import`, {
          objectKey: uploadUrlResult.data.objectKey,
          category,
          confidence,
          ownerGroupId,
          srcLang,
          tgtLang,
          vendorId
        })
      })
    )
  }, [state.formData, t])

  return {
    state,
    dispatch,
    handleSubmit
  }
}
