import React, { useEffect, useState } from 'react'
import { Button, ButtonToolbar, Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import useAxios from 'axios-hooks'

import Job from '../../../lib/Job'
import Project from '../../../lib/Project'

import config from '../../../config'
import { useLoginUser } from '../../../hooks/useLoginUser'
import { useProjectDetailPage } from '../useProjectDetailPage'
import { OverlayTriggerButton } from '../../../components/common/OverlayTriggerButton'

interface Props {
  project: Project
  selectedJobs: Job[]
}

const ActionsRow: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  const loginUser = useLoginUser()

  const { state, dispatch } = useProjectDetailPage()

  const [generateDownloadUrl, refetchDownloadUrl] = useAxios(
    {
      url: `${config[config.STAGE].endpoint}/api/v1/generateDownloadUrl`,
      data: {
        objectKey: `projects/${props.project.name}-${props.project._id}/${props.project.name}.zip`
      },
      method: 'POST'
    },
    { manual: true }
  )

  const objectExists = generateDownloadUrl.data?.exists
  const downloadUrl = generateDownloadUrl.data?.presignedUrl

  // 翻訳キットをダウンロード中フラグ
  const [downloadingTranslationKit, setDownloadingTranslationKit] = useState(false)

  const hasOrderCancelled = (): boolean => {
    if (props.project.cancelledAt || props.project.cancelledBy) {
      return true
    }
    return false
  }

  const hasOrderApproved = (): boolean => {
    if (props.project.approvedAt || props.project.approvedBy) {
      return true
    }
    return false
  }

  const canAddOrRemoveJobs = (): boolean => {
    if (hasOrderApproved() || hasOrderCancelled()) {
      if (loginUser?.organizationType === 'cistate' && loginUser?.role === 'admin') {
        if (props.project.mdmJobsCount && props.project.mdmJobsCount > 0) {
          return false
        }
        return true
      }
      return false
    }
    return true
  }

  useEffect(() => {
    refetchDownloadUrl()
  }, [state.canCreateTranslationKit, refetchDownloadUrl])

  return (
    <Container fluid>
      <Row>
        <Col>
          <ButtonToolbar className="mb-3">
            {loginUser?.organizationType !== 'vendor' && (
              <>
                <Button
                  disabled={!canAddOrRemoveJobs()}
                  onClick={(): void => {
                    dispatch({
                      type: 'UPDATE',
                      payload: {
                        ...state,
                        showSelectJobsDialog: true
                      }
                    })
                  }}
                  size="sm"
                  style={!canAddOrRemoveJobs() ? { pointerEvents: 'none' } : {}}
                >
                  {t('ジョブを追加')}
                </Button>
                <OverlayTriggerButton
                  // disabled={}
                  tooltipLabel={
                    props.selectedJobs.length === 0
                      ? t('ジョブが選択されていません')
                      : t('ジョブを除外できません')
                  }
                  buttonLabel={t('ジョブをプロジェクトから除外')}
                  isOverlayed={(): boolean =>
                    props.selectedJobs.length === 0 || !canAddOrRemoveJobs()
                  }
                  tooltipId="tooltip-job-not-selected"
                  variant="secondary"
                  buttonClassName="ml-2"
                  onClick={(): void => {
                    dispatch({
                      type: 'UPDATE',
                      payload: {
                        ...state,
                        showRemoveJobsDialog: true
                      }
                    })
                  }}
                  size="sm"
                />
              </>
            )}
          </ButtonToolbar>
        </Col>
        <Col sm="auto">
          <ButtonToolbar className="mb-3">
            <OverlayTriggerButton
              disabled={hasOrderCancelled() || !objectExists}
              tooltipLabel={t('翻訳キットを生成してください')}
              buttonLabel={t('翻訳キットをダウンロード')}
              isOverlayed={(): boolean => !objectExists}
              isLoading={(): boolean => downloadingTranslationKit}
              tooltipId="tooltip-cannot-download-translation-kit"
              buttonClassName="ml-2"
              variant="secondary"
              onClick={async (): Promise<void> => {
                await refetchDownloadUrl()
                if (objectExists && downloadUrl) {
                  setDownloadingTranslationKit(true)
                  window.open(downloadUrl, '_blank')
                  setDownloadingTranslationKit(false)
                }
              }}
              size="sm"
            />
            <OverlayTriggerButton
              disabled={hasOrderCancelled()}
              tooltipLabel={
                state.canCreateTranslationKit === null ? t('ジョブがありません') : t('生成中です')
              }
              buttonLabel={t('翻訳キットを生成')}
              isOverlayed={(): boolean => !state.canCreateTranslationKit}
              isLoading={(): boolean =>
                state.canCreateTranslationKit !== null && !state.canCreateTranslationKit
              }
              tooltipId="tooltip-cannot-create-translation-kit"
              buttonClassName="ml-2"
              variant="secondary"
              onClick={(): void => {
                dispatch({
                  type: 'UPDATE',
                  payload: {
                    ...state,
                    showCreateTranslationKitDialog: true
                  }
                })
              }}
              size="sm"
            />

            <Button
              disabled={hasOrderCancelled()}
              onClick={(): void => {
                dispatch({
                  type: 'UPDATE',
                  payload: {
                    ...state,
                    showUploadTranslatedXliffDialog: true
                  }
                })
              }}
              variant="secondary"
              className="ml-2"
              size="sm"
              style={hasOrderCancelled() ? { pointerEvents: 'none' } : {}}
            >
              {t('翻訳済みXLIFFをアップロード')}
            </Button>
            <Button
              disabled={hasOrderCancelled()}
              onClick={(): void => {
                dispatch({
                  type: 'UPDATE',
                  payload: {
                    ...state,
                    showDownloadTranslatedFilesDialog: true
                  }
                })
              }}
              variant="secondary"
              className="ml-2"
              size="sm"
              style={hasOrderCancelled() ? { pointerEvents: 'none' } : {}}
            >
              {t('翻訳済みジョブをダウンロード')}
            </Button>
            {loginUser?.organizationType !== 'vendor' && (
              <Button
                disabled={
                  hasOrderCancelled() || (props.project.status === 'completed' ? true : false)
                }
                onClick={(): void => {
                  dispatch({
                    type: 'UPDATE',
                    payload: {
                      ...state,
                      showSendToMdmDialog: true
                    }
                  })
                }}
                variant="secondary"
                className="ml-2"
                size="sm"
                style={
                  hasOrderCancelled() || (props.project.status === 'completed' ? true : false)
                    ? { pointerEvents: 'none' }
                    : {}
                }
              >
                {t('ProjectDetail_Toolbar_完了')}
              </Button>
            )}
          </ButtonToolbar>
        </Col>
      </Row>
    </Container>
  )
}

export default ActionsRow
