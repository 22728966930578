import { useEffect, useState } from 'react'
import axios from 'axios'
import config from '../config'
import Project from '../lib/Project'

type useTranslationKitDownloadUrlOptions = {
  project: Project
  polling: boolean
}

type useTranslationKitDownloadUrl = (
  options: useTranslationKitDownloadUrlOptions
) => {
  downloadUrl: string | null
  objectExists: boolean
  loading: boolean
  error: Error | null
}
export const useTranslationKitDownloadUrl: useTranslationKitDownloadUrl = ({
  project,
  polling
}) => {
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null)
  const [objectExists, setObjectExists] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    const getTranslationKitDownloadUrl = async (): Promise<void> => {
      if (!project) {
        return
      }
      if (!polling) {
        setLoading(true)
      }
      try {
        const result = await axios.post<{ presignedUrl: string; exists: boolean }>(
          `${config[config.STAGE].endpoint}/api/v1/generateDownloadUrl`,
          {
            objectKey: `projects/${project.name}-${project._id}/${project.name}.zip`
          }
        )
        setObjectExists(result.data.exists)
        setDownloadUrl(result.data.presignedUrl)
        setError(null)
      } catch (error) {
        console.log(JSON.stringify(error, null, 2))
        setError(error)
      }
      if (!polling) {
        setLoading(false)
      }
    }
    getTranslationKitDownloadUrl()
    if (polling) {
      const getTranslationKitDownloadUrlTimer = setInterval(getTranslationKitDownloadUrl, 1000)
      return (): void => clearInterval(getTranslationKitDownloadUrlTimer)
    }
  }, [project, polling])
  return { downloadUrl, objectExists, loading, error }
}
