import React from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import Job from '../../../lib/Job'

interface Props {
  areProjectIdsOfSelectedJobsNotSet: boolean
  areLangPairsOfSelectedJobsIdentical: boolean
  doesSelectedJobsIncludeInitializingJobs: boolean
  doesSelectedJobsIncludeErrorJobs: boolean
  className?: string
  jobs: Job[]
  onClick: Function
}

const RegisterProjectButton: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  return (
    <>
      {props.areProjectIdsOfSelectedJobsNotSet &&
      props.areLangPairsOfSelectedJobsIdentical &&
      !props.doesSelectedJobsIncludeInitializingJobs &&
      !props.doesSelectedJobsIncludeErrorJobs ? (
        <Button className={props.className} onClick={(): void => props.onClick()} variant="primary">
          {t('プロジェクト登録')}
        </Button>
      ) : (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-register-disabled">
              {props.jobs.length === 0 ? (
                <>{t('ジョブが選択されていません')}</>
              ) : (
                <>
                  {!props.areProjectIdsOfSelectedJobsNotSet && (
                    <>{t('束ね済みのジョブが選択されています')}</>
                  )}
                  {!props.areProjectIdsOfSelectedJobsNotSet &&
                    !props.areLangPairsOfSelectedJobsIdentical && <br />}
                  {!props.areLangPairsOfSelectedJobsIdentical && (
                    <>{t('異なる言語ペアのジョブが選択されています')}</>
                  )}
                  {(!props.areProjectIdsOfSelectedJobsNotSet ||
                    !props.areLangPairsOfSelectedJobsIdentical) &&
                    props.doesSelectedJobsIncludeInitializingJobs && <br />}
                  {props.doesSelectedJobsIncludeInitializingJobs && (
                    <>{t('準備中のジョブが選択されています')}</>
                  )}
                  {props.doesSelectedJobsIncludeErrorJobs && (
                    <>{t('エラーになっているジョブが選択されています')}</>
                  )}
                </>
              )}
            </Tooltip>
          }
        >
          <span className="d-inline-block">
            <Button
              className={props.className}
              disabled
              style={{ pointerEvents: 'none' }}
              variant="primary"
            >
              {t('プロジェクト登録')}
            </Button>
          </span>
        </OverlayTrigger>
      )}
    </>
  )
}

export default RegisterProjectButton
