import React, { useEffect } from 'react'
import { Button, ButtonToolbar } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import useAxios from 'axios-hooks'
import queryString from 'query-string'

import config from '../../../config'
import Job from '../../../lib/Job'
import RegisterProjectButton from './RegisterProjectButton'
import EditJobsButton from './EditJobsButton'
import RegisterJobsButton from './RegisterJobsButton'
import DeleteJobsButton from './DeleteJobsButton'
import ExportCsvButton from './ExportCsvButton'
import ToggleSortOrderButton from '../../../components/ToggleSortOrderButton'
import { useLoginUser } from '../../../hooks/useLoginUser'
import useQueryString from '../../../hooks/useQueryString'

interface Props extends RouteComponentProps {
  selectedJobs: Job[]
  showEditJobsDialog: Function
  showJobfiltersDialog: Function
  showRegisterProjectDialog: Function
  showRegisterJobsDialog: Function
  showDeleteJobsDialog: Function
}

const Toolbar: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  const loginUser = useLoginUser()

  const [{ data: countData }, countRefetch] = useAxios(
    config[config.STAGE].endpoint + '/api/v1/jobs/count' + props.location.search
  )
  const [{ data: totalData }, totalRefetch] = useAxios(
    config[config.STAGE].endpoint + '/api/v1/jobs/count'
  )

  const { sortBy, orderByAsc } = useQueryString(props.location.search)

  useEffect(() => {
    countRefetch()
    totalRefetch()
  }, [countRefetch, props, props.location.search, totalRefetch])

  const areProjectIdsOfSelectedJobsNotSet = (): boolean => {
    const projectIdTypes = props.selectedJobs.map(job => {
      return typeof job.projectId
    })

    return projectIdTypes.indexOf('string') === -1 ? true : false
  }

  const areLangPairsOfSelectedJobsIdentical = (): boolean => {
    const sourceLangs = ((langs: string[]): string[] => {
      return Array.from(new Set(langs))
    })(
      props.selectedJobs.map(job => {
        return job.srcLang
      })
    )

    const targetLangs = ((langs: string[]): string[] => {
      return Array.from(new Set(langs))
    })(
      props.selectedJobs.map(job => {
        return job.tgtLang
      })
    )

    return sourceLangs.length === 1 && targetLangs.length === 1 ? true : false
  }

  const areOriginsOfSelectedJobsDashboard = (): boolean => {
    return (
      props.selectedJobs
        .map(job => {
          return job.origin
        })
        .filter(origin => origin !== 'dashboard').length === 0
    )
  }

  const doesSelectedJobsIncludeInitializingJobs = (): boolean => {
    const statuses = props.selectedJobs.map(job => {
      return job.status
    })

    return statuses.indexOf('initializing') !== -1 ? true : false
  }

  const doesSelectedJobsIncludeErrorJobs = (): boolean => {
    const statuses = props.selectedJobs.map(job => {
      return job.status
    })

    return statuses.indexOf('error') !== -1 ? true : false
  }

  const clearFilters = (): void => {
    const qs = props.location.search
    const query = queryString.parse(qs)
    Object.keys(query).forEach((key: string) => {
      if (!key.startsWith('options[sort]')) {
        delete query[key]
      }
    })

    props.history.push(
      '/jobs?archivedAt=null' +
        (Object.keys(query).length > 0 ? '&' + queryString.stringify(query) : '')
    )
  }

  const onChangeSortBy = (value: string): void => {
    const qs = props.location.search
    const queries = queryString.parse(qs)
    delete queries[`options[sort][${sortBy}]`]
    queries[`options[sort][${value}]`] = orderByAsc ? '1' : '-1'

    const options = queryString.stringify(queries)
    props.history.push('/jobs?' + options)
  }

  const toggleOrderByAsc = (): void => {
    const qs = props.location.search
    const queries = queryString.parse(qs)
    queries[`options[sort][${sortBy ? sortBy : 'createdAt'}]`] = !orderByAsc ? '1' : '-1'

    const options = queryString.stringify(queries)
    props.history.push('/jobs?' + options)
  }

  return (
    <div>
      <ButtonToolbar className="ml-3 mr-3 mb-3">
        <RegisterProjectButton
          areProjectIdsOfSelectedJobsNotSet={areProjectIdsOfSelectedJobsNotSet()}
          areLangPairsOfSelectedJobsIdentical={areLangPairsOfSelectedJobsIdentical()}
          doesSelectedJobsIncludeInitializingJobs={doesSelectedJobsIncludeInitializingJobs()}
          doesSelectedJobsIncludeErrorJobs={doesSelectedJobsIncludeErrorJobs()}
          jobs={props.selectedJobs}
          onClick={(): void => props.showRegisterProjectDialog()}
        />
        <RegisterJobsButton className="ml-2" onClick={(): void => props.showRegisterJobsDialog()} />
        <EditJobsButton
          className="ml-2"
          jobs={props.selectedJobs}
          onClick={(): void => props.showEditJobsDialog()}
        />
        {loginUser?.organizationType === 'cistate' && loginUser?.role === 'admin' && (
          <DeleteJobsButton
            areOriginsOfSelectedJobsDashboard={areOriginsOfSelectedJobsDashboard()}
            areProjectIdsOfSelectedJobsNotSet={areProjectIdsOfSelectedJobsNotSet()}
            jobs={props.selectedJobs}
            onClick={(): void => props.showDeleteJobsDialog()}
          />
        )}
        <ExportCsvButton />
        <Button className="ml-2" disabled variant="link">
          {countData?.count} / {totalData?.count}
        </Button>
        <Button
          className="ml-2"
          onClick={(): void => props.showJobfiltersDialog()}
          variant="light"
          data-testid="job-filters-button"
        >
          {t('絞り込み')}
        </Button>
        <Button className="ml-2" onClick={clearFilters} variant="light">
          {t('絞り込み解除')}
        </Button>
        <select
          className="ml-2"
          onChange={(e: React.FormEvent<HTMLSelectElement>): void =>
            onChangeSortBy(e.currentTarget.value)
          }
          value={sortBy ? sortBy : 'createdAt'}
        >
          <option value="createdAt">{t('作成日時')}</option>
          <option value="updatedAt">{t('更新日時')}</option>
          <option value="name">{t('ファイル名')}</option>
          <option value="properties.anken_id">{t('案件ID')}</option>
          <option value="properties.category_code">{t('カテゴリコード')}</option>
          <option value="properties.brand_code">{t('ブランドコード')}</option>
          <option value="srcLang">{t('ソース言語')}</option>
          <option value="tgtLang">{t('ターゲット言語')}</option>
          <option value="status">{t('ステータス')}</option>
          <option value="project.name">{t('見積束ねプロジェクト名')}</option>
        </select>
        <ToggleSortOrderButton
          onClick={toggleOrderByAsc}
          orderByAsc={orderByAsc ? orderByAsc : false}
        />
      </ButtonToolbar>
    </div>
  )
}

export default withRouter(Toolbar)
