import React, { createContext, useContext, useReducer } from 'react'

type State = {
  showImportTmxDialog: boolean
  showToast: boolean
}

type Action = {
  type: string
  payload: State
}

const initialState: State = {
  showImportTmxDialog: false,
  showToast: false
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'UPDATE':
      return action.payload
    default:
      return state
  }
}

const TMEntriesPageContext = createContext(
  {} as {
    state: State
    dispatch: React.Dispatch<Action>
  }
)

export const TMEntriesPageProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <TMEntriesPageContext.Provider value={{ state, dispatch }}>
      {children}
    </TMEntriesPageContext.Provider>
  )
}

export const TMEntriesPageConsumer = TMEntriesPageContext.Consumer

export const useTMEntriesPage = (): {
  state: State
  dispatch: React.Dispatch<Action>
} => {
  return useContext(TMEntriesPageContext)
}
