import React, { useLayoutEffect, useState } from 'react'
import { Button, ButtonToolbar, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import dateformat from 'dateformat'

import config from '../../config'
import { statusCodeToStatusName } from '../../lib/Status'
import { projectTypeCodeToProjectTypeName } from '../../lib/Utils'
import Project from '../../lib/Project'
import Vendor from '../../lib/Vendor'
import FoldableCard from '../../components/common/FoldableCard'
import { OverlayTriggerButton } from '../../components/common/OverlayTriggerButton'
import { useLoginUser } from '../../hooks/useLoginUser'
import { useProjectDetailPage } from './useProjectDetailPage'

interface Props {
  project: Project
  refreshJobList: Function
}

interface RequestBody {
  projectType?: string
  status?: string
  vendorId?: string | null // "なし" は null で表現
}

// "なし"を表す vendorId。selectのoptionとして使用
const EMPTY = 'local-empty'

const ProjectDetailCard: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  const loginUser = useLoginUser()

  const { state, dispatch } = useProjectDetailPage()

  const [loading, setLoading] = useState(false)
  const [editable, setEditable] = useState(false)

  const [status, setStatus] = useState('')
  const [projectType, setProjectType] = useState('')
  const [vendorId, setVendorId] = useState(EMPTY)

  const [vendors, setVendors] = useState<Vendor[]>([])

  useLayoutEffect(() => {
    const fetchData = async (): Promise<void> => {
      const vendorsResponse = await axios.get<Vendor[]>(
        config[config.STAGE].endpoint +
          '/api/v1/vendors?' +
          'isEnabled=true' +
          `&srcLang=${props.project.srcLang}` +
          `&tgtLang=${props.project.tgtLang}`
      )
      setVendors(vendorsResponse.data.filter(v => v.ownerGroupId === props.project.ownerGroupId))
      setVendorId(props.project.vendorId || EMPTY)
      // setStatus(props.project.status || '')
      setProjectType(props.project.projectType || '')
    }

    fetchData()
  }, [props])

  const onChangeStatus = (event: React.FormEvent<HTMLSelectElement>): void => {
    setStatus(event.currentTarget.value)
  }

  const onChangeProjectType = (event: React.FormEvent<HTMLSelectElement>): void => {
    setProjectType(event.currentTarget.value)
  }

  const onChangeVendorId = (event: React.FormEvent<HTMLSelectElement>): void => {
    setVendorId(event.currentTarget.value)
  }

  const onFinishEditing = (): void => {
    setLoading(true)

    const requestBody: RequestBody = {}

    if (status !== props.project.status && status !== '') {
      requestBody.status = status
    }

    if (projectType !== props.project.projectType) {
      requestBody.projectType = projectType
    }

    if (vendorId !== props.project.vendorId) {
      requestBody.vendorId = vendorId === EMPTY ? null : vendorId
    }

    if (Object.keys(requestBody).length > 0) {
      axios
        .patch(
          config[config.STAGE].endpoint + '/api/v1/projects/' + props.project._id,
          requestBody,
          {}
        )
        .then(() => {
          setLoading(false)
          setEditable(false)
          props.refreshJobList()
        })
        .catch(e => console.error(e))
    } else {
      setLoading(false)
      setEditable(false)
    }
  }

  const onCancelEditing = (): void => {
    setEditable(false)
    setStatus(props.project.status ? props.project.status : '')
    setProjectType(props.project.projectType ? props.project.projectType : '')
    setVendorId(props.project.vendorId ? props.project.vendorId : EMPTY)
  }

  const hasOrderCancelled = (): boolean => {
    if (props.project.cancelledAt || props.project.cancelledBy) {
      return true
    }
    return false
  }

  const hasOrderApproved = (): boolean => {
    if (props.project.approvedAt || props.project.approvedBy) {
      return true
    }
    return false
  }

  return (
    <FoldableCard title={t('プロジェクト情報')}>
      {loginUser?.organizationType === 'vendor' && (
        <ButtonToolbar className="mb-3">
          <Button
            onClick={(): void => {
              dispatch({
                type: 'UPDATE',
                payload: {
                  ...state,
                  showQuotedAmountDialog: true
                }
              })
            }}
            variant="secondary"
          >
            {t('見積金額入力')}
          </Button>
        </ButtonToolbar>
      )}
      {loginUser?.organizationType !== 'vendor' && (
        <ButtonToolbar className="mb-3">
          {!editable ? (
            <>
              <Button onClick={(): void => setEditable(true)} variant="secondary">
                {t('プロジェクト編集')}
              </Button>
              <Button
                className="ml-2"
                disabled={hasOrderCancelled() || hasOrderApproved()}
                variant="danger"
                onClick={(): void => {
                  dispatch({
                    type: 'UPDATE',
                    payload: {
                      ...state,
                      showReturnSourceToMdmDialog: true
                    }
                  })
                }}
                style={hasOrderCancelled() || hasOrderApproved() ? { pointerEvents: 'none' } : {}}
              >
                {t('ソース戻し')}
              </Button>
              <Button
                className="ml-2"
                disabled={hasOrderCancelled() || hasOrderApproved()}
                onClick={(): void => {
                  dispatch({
                    type: 'UPDATE',
                    payload: {
                      ...state,
                      showCancelProjectDialog: true
                    }
                  })
                }}
                variant="danger"
                style={hasOrderCancelled() || hasOrderApproved() ? { pointerEvents: 'none' } : {}}
              >
                {t('発注キャンセル')}
              </Button>
              <Button
                className="ml-2"
                disabled={hasOrderCancelled() || hasOrderApproved()}
                onClick={(): void => {
                  dispatch({
                    type: 'UPDATE',
                    payload: {
                      ...state,
                      showApproveOrderDialog: true
                    }
                  })
                }}
                variant="secondary"
                style={hasOrderCancelled() || hasOrderApproved() ? { pointerEvents: 'none' } : {}}
              >
                {t('発注承認')}
              </Button>
            </>
          ) : (
            <>
              <OverlayTriggerButton
                tooltipLabel={t('処理中')}
                buttonLabel={t('編集完了')}
                isOverlayed={(): boolean => loading}
                isLoading={(): boolean => loading}
                tooltipId="tooltip-editing-project-in-progress"
                variant="primary"
                onClick={onFinishEditing}
              />
              <Button
                disabled={loading}
                className="ml-2"
                onClick={onCancelEditing}
                style={loading ? { pointerEvents: 'none' } : {}}
                variant="secondary"
              >
                {t('キャンセル')}
              </Button>
            </>
          )}
        </ButtonToolbar>
      )}
      <Row>
        <Col>
          <ul>
            <li>
              {t('ステータス')}:{' '}
              {editable ? (
                <select disabled={loading} value={status} onChange={onChangeStatus}>
                  <option value="">{t('(変更なし)')}</option>
                  <option value="prepared">{t('準備処理完了')}</option>
                  <option value="quote_requested">{t('見積依頼中')}</option>
                  <option value="quote_ready">{t('見積提出済み')}</option>
                  <option value="order_confirmed">{t('発注確定')}</option>
                  {/* <option value="translating">{t('翻訳中')}</option> */}
                  <option value="translated">{t('翻訳済み')}</option>
                  <option value="reviewed">{t('レビュー済み')}</option>
                  <option value="completed">{t('完了')}</option>
                </select>
              ) : (
                <>{props.project.status ? statusCodeToStatusName(props.project.status) : ''}</>
              )}
            </li>
            <li>
              {t('プロジェクトタイプ')}:{' '}
              {editable ? (
                <select disabled={loading} value={projectType} onChange={onChangeProjectType}>
                  <option value="ht_only">{t('人手翻訳')}</option>
                  <option value="mt_and_postedit">{t('機械翻訳+ポストエディット')}</option>
                  <option value="mt_only">{t('機械翻訳のみ')}</option>
                </select>
              ) : (
                <>
                  {props.project.projectType
                    ? projectTypeCodeToProjectTypeName(props.project.projectType)
                    : ''}
                </>
              )}
            </li>
            <li>
              {t('ソース言語')}: {props.project.srcLang}
            </li>
            <li>
              {t('ターゲット言語')}: {props.project.tgtLang}
            </li>
          </ul>
        </Col>
        <Col>
          <ul>
            <li>
              {t('発注先翻訳会社')}:{' '}
              {editable ? (
                <select
                  disabled={loading}
                  onChange={onChangeVendorId}
                  value={vendorId || undefined}
                >
                  {[{ _id: EMPTY, name: t('(なし)') }, ...vendors].map(vendor => {
                    return (
                      <option key={vendor._id} value={vendor._id}>
                        {vendor.name}
                      </option>
                    )
                  })}
                </select>
              ) : (
                <>{props.project.vendor?.name}</>
              )}
            </li>
            <li>
              {t('担当者')}: {props.project.owner?.username}
            </li>
            <li>
              {t('担当者所属')}: {props.project.ownerGroup?.name}
            </li>
            {props.project.approvedBy && (
              <li>
                {t('発注承認者')}: {props.project.approvedBy}
              </li>
            )}
            {props.project.approvedAt && (
              <li>
                {t('発注承認日時')}: {dateformat(props.project.approvedAt, 'yyyy-mm-dd HH:MM:ss')}
              </li>
            )}
            {props.project.cancelledBy && (
              <li>
                {t('キャンセル実行者')}: {props.project.cancelledBy}
              </li>
            )}
            {props.project.cancelledAt && (
              <li>
                {t('キャンセル実行日時')}:{' '}
                {dateformat(props.project.cancelledAt, 'yyyy-mm-dd HH:MM:ss')}
              </li>
            )}
          </ul>
        </Col>
        <Col>
          <ul>
            <li>
              {t('合計ワード数')}: {props.project.wordCount || 0}
            </li>
            <li>
              {t('合計加重ワード数')}: {props.project.weightedWordCount || 0}
            </li>
          </ul>
        </Col>
        <Col>
          <ul>
            <li>
              {t('標準単価')}: {props.project.vendor?.unitPrice || 0}{' '}
              {props.project.vendor?.currency}
            </li>
            <li>
              {t('算定金額')}: {props.project.calculatedAmount || 0}{' '}
              {props.project.vendor?.currency}
            </li>
            <li>
              {t('見積金額')}: {props.project.quotedAmount || 0} {props.project.vendor?.currency}
            </li>
            <li>
              {t('発注金額')}: {props.project.orderAmount || 0} {props.project.vendor?.currency}
            </li>
          </ul>
        </Col>
      </Row>
    </FoldableCard>
  )
}

export default ProjectDetailCard
