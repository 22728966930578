import React from 'react'
import { Button, Modal, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import Job from '../../../lib/Job'
import Project from '../../../lib/Project'
import { OverlayTriggerButton } from '../../common/OverlayTriggerButton'
import JobList from '../../JobList'
import useSendToMdmDialog, { State, Action } from './useSendToMdmDialog'
import { BackendError } from '../../../lib/BackendError'

type ContainerProps = {
  close: (updated: boolean) => void
  project: Project
  title: string
}

type Props = ContainerProps & {
  state: State
  dispatch: React.Dispatch<Action>
  onKeyPress: (e: React.KeyboardEvent<HTMLFormElement>) => void
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
}

export const Component: React.FC<Props> = (props: Props) => {
  const { state, dispatch, handleSubmit } = props
  const { t } = useTranslation()

  return (
    <Modal onHide={(): void => props.close(false)} show={true} size="lg">
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>{props.title}</Modal.Header>
        <Modal.Body style={{ height: '70vh', marginBottom: '20px' }}>
          <div style={{ marginBottom: '20px' }}>
            <span>{t('翻訳済みジョブ数')}:</span>
            <span data-testid="translated-jobs-count">{state.jobsCount.translated}</span>/
            <span data-testid="total-jobs-count">{state.jobsCount.total}</span>
            <OverlayTriggerButton
              buttonClassName={'float-right ml-2'}
              buttonLabel={t('選択したジョブのステータスを完了にする')}
              tooltipId="return-to-mdm-tooltip"
              tooltipLabel={t('処理中')}
              isOverlayed={(): boolean => state.loading}
              isLoading={(): boolean => state.loading}
              size="sm"
              variant="info"
              disabled={state.selectedJobs.length === 0}
              type="submit"
              data-testid="complete-selected-jobs-button"
            />
            <span
              style={state.message.isError ? { color: 'red' } : undefined}
              className="float-right ml-auto"
            >
              {state.message.text}
            </span>
          </div>
          <JobList
            options={`projectId=${props.project._id}&[translatedAt][$ne]=null`}
            showDownloadButtonColumn={false}
            showProjectInfoColumns={false}
            showJobNameAsLink={false}
            updateSelection={(selected: Job[]): void => {
              dispatch({
                type: 'SET_SELECTED_JOBS',
                payload: { selectedJobs: selected }
              })
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={(): void => props.close(false)} variant="primary">
            {t('閉じる')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { close, project } = props
  const { state, dispatch, handleSubmit: completeJobs } = useSendToMdmDialog(project)
  const { t } = useTranslation()

  const onKeyPress = (e: React.KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === 'Enter' /* Enter */) {
      e.preventDefault()
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    if (window.confirm(t('選択したジョブのステータスを完了にします。よろしいですか？'))) {
      e.preventDefault()
      try {
        await completeJobs()
        close(true)
      } catch (error) {
        if (error.response) {
          const errorInfo: BackendError = error.response.data
          dispatch({
            type: 'SET_MESSAGE',
            payload: { message: errorInfo.message, isError: true }
          })
        } else {
          dispatch({
            type: 'SET_MESSAGE',
            payload: { message: error.message, isError: true }
          })
        }
      } finally {
        dispatch({ type: 'SET_IS_LOADING', payload: { loading: false } })
      }
    }
  }

  return (
    <Component
      {...props}
      close={close}
      project={project}
      state={state}
      dispatch={dispatch}
      onKeyPress={onKeyPress}
      handleSubmit={handleSubmit}
    />
  )
}

Container.displayName = 'SendToMdmDialog'
export default Container
