import React from 'react'
import { useTranslation } from 'react-i18next'

import Job from '../../../lib/Job'
import { OverlayTriggerButton } from '../../../components/common/OverlayTriggerButton'

interface Props {
  areOriginsOfSelectedJobsDashboard: boolean
  areProjectIdsOfSelectedJobsNotSet: boolean
  jobs: Job[]
  onClick: Function
}

const DeleteJobsButton: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  const isButtonEnabled = (): boolean => {
    return (
      (props.jobs.length === 0 ? false : true) &&
      props.areOriginsOfSelectedJobsDashboard &&
      props.areProjectIdsOfSelectedJobsNotSet
    )
  }

  const tooltipLabel =
    props.jobs.length === 0 ? t('ジョブが選択されていません') : '削除できないジョブが含まれています'

  return (
    <OverlayTriggerButton
      buttonClassName="ml-2"
      buttonLabel={t('選択したジョブを削除')}
      isOverlayed={(): boolean => !isButtonEnabled()}
      onClick={(): void => props.onClick()}
      tooltipId="delete-jobs-button-tooltip"
      tooltipLabel={tooltipLabel}
      variant="danger"
    />
  )
}

export default DeleteJobsButton
