import React, { ReactNode, useState } from 'react'
import { Card } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'

interface Props {
  children?: ReactNode
  className?: string
  title?: string
}

const FoldableCard: React.FC<Props> = (props: Props) => {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <Card className={props.className}>
      <Card.Header
        style={{ cursor: 'pointer', userSelect: 'none' }}
        onClick={(): void => setIsOpen(!isOpen)}
      >
        {isOpen ? (
          <FontAwesomeIcon icon={faChevronDown} />
        ) : (
          <FontAwesomeIcon icon={faChevronRight} />
        )}{' '}
        {props.title}
      </Card.Header>
      {isOpen ? <Card.Body>{props.children}</Card.Body> : <></>}
    </Card>
  )
}

export default FoldableCard
