import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import Job from '../../../lib/Job'
import { OverlayTriggerButton } from '../../common/OverlayTriggerButton'
import { BackendError } from '../../../lib/BackendError'
import useRemoveJobsDialog, { State, Action } from './useRemoveJobsDialog'

type ContainerProps = {
  close: (updated: boolean) => void
  jobs: Job[]
}

type Props = ContainerProps & {
  state: State
  dispatch: React.Dispatch<Action>
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
}

export const Component: React.FC<Props> = (props: Props) => {
  const { close, jobs, state, handleSubmit } = props

  const { t } = useTranslation()

  return (
    <Modal onHide={(): void => close(false)} show={true} size="lg">
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {t('以下のジョブをプロジェクトから除外します。よろしいですか？')}
          <ul data-testid="jobs-list">
            {jobs.map(job => {
              return <li key={job._id}>{job.name}</li>
            })}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          {state.message.isError ? (
            <span style={{ color: 'red' }}>{state.message.text}</span>
          ) : (
            <span>{state.message.text}</span>
          )}
          <OverlayTriggerButton
            tooltipLabel={t('処理中')}
            buttonLabel={t('OK')}
            isOverlayed={(): boolean => state.loading}
            isLoading={(): boolean => state.loading}
            variant="danger"
            tooltipId="tooltip-removing-jobs-in-progress"
            type="submit"
            data-testid="remove-jobs-button"
          />
          <Button
            disabled={state.loading}
            onClick={(): void => close(false)}
            style={state.loading ? { pointerEvents: 'none' } : {}}
            variant="secondary"
          >
            {t('キャンセル')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { close, jobs } = props
  const { state, dispatch, handleSubmit: removeJobs } = useRemoveJobsDialog(jobs)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    try {
      await removeJobs()
      close(true)
    } catch (error) {
      if (error.response) {
        const errorInfo: BackendError = error.response.data
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: errorInfo.message, isError: true }
        })
      } else {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: error.message, isError: true }
        })
      }
    } finally {
      dispatch({ type: 'SET_IS_LOADING', payload: { loading: false } })
    }
  }

  return (
    <Component
      {...props}
      close={close}
      state={state}
      dispatch={dispatch}
      handleSubmit={handleSubmit}
    />
  )
}

Container.displayName = 'RemoveJobsDialog'
export default Container
