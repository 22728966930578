import React, { useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { ColDef } from '@ag-grid-community/core'
import { AllCommunityModules } from '@ag-grid-community/all-modules'
import { AgGridReact } from '@ag-grid-community/react'

import useTMList from './useTMList'

type Props = RouteComponentProps & {
  inlineEditMode: boolean
  toggleRefresh?: boolean
}

const TMList: React.FC<Props> = (props: Props) => {
  const { toggleRefresh, inlineEditMode } = props
  const qs = props.location.search

  const { columnDefs, gridApi, gridOptions, onBodyScroll } = useTMList({
    qs,
    toggleRefresh
  })

  useEffect(() => {
    gridApi?.stopEditing()
    const colIds = [
      'srcTmx',
      'tgtTmx',
      'confidence',
      'ownerGroupId',
      'vendorId',
      'category',
      'active'
    ]
    const newColDefs = columnDefs.map((colDef: ColDef) => {
      if (colDef.colId !== undefined) {
        if (colIds.includes(colDef.colId)) {
          colDef.editable = inlineEditMode
          colDef.singleClickEdit = inlineEditMode
          return colDef
        }
        return colDef
      }
      return colDef
    })
    gridApi?.setColumnDefs(newColDefs)
    gridApi?.refreshCells()
  }, [
    columnDefs,
    gridApi?.refreshCells,
    gridApi?.setColumnDefs,
    gridApi?.stopEditing,
    inlineEditMode
  ])

  return (
    <div className="ag-theme-balham" style={{ height: '100%', width: '100%' }}>
      <AgGridReact
        gridOptions={gridOptions}
        modules={AllCommunityModules}
        onBodyScroll={onBodyScroll}
      />
    </div>
  )
}

export default withRouter(TMList)
