import React from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import User from '../../../lib/User'
import OwnerGroup from '../../../lib/OwnerGroup'
import Vendor from '../../../lib/Vendor'
import { useLoginUser } from '../../../hooks/useLoginUser'
import useProjectFiltersDialog, { State, Action } from './useProjectFiltersDialog'
import { useOwnerGroups } from '../../../hooks/useOwnerGroups'
import { useVendors } from '../../../hooks/useVendors'

type ContainerProps = RouteComponentProps & {
  close: Function
  title?: string
}

type Props = ContainerProps & {
  state: State
  dispatch: React.Dispatch<Action>
  onKeyPress: (e: React.KeyboardEvent<HTMLFormElement>) => void
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
}

export const Component: React.FC<Props> = (props: Props) => {
  const { state, dispatch, onKeyPress, handleSubmit } = props
  const { t } = useTranslation()
  const loginUser = useLoginUser()
  const ownerGroups = useOwnerGroups()
  const vendors = useVendors()

  const clearFilters = (): void => {
    dispatch({ type: 'CLEAR_FILTERS' })
  }

  return (
    <Modal
      onHide={(): void => props.close()}
      show={true}
      size="lg"
      data-testid="project-filters-dialog"
    >
      <Form onKeyPress={onKeyPress} onSubmit={handleSubmit}>
        <Modal.Header closeButton>{t('絞り込み')}</Modal.Header>
        <Modal.Body>
          <Form.Group as={Row}>
            <Form.Label column sm={4}>
              {t('プロジェクト名')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                className="formTableCell"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>): void =>
                  dispatch({
                    type: 'SET_FORM_VALUE',
                    payload: { name: e.currentTarget.value }
                  })
                }
                value={state.formData.name}
                data-testid="project-name"
              />
              <Form.Text>{t('前方一致')}</Form.Text>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={4}>
              {t('ソース言語')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                className="formTableCell"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>): void =>
                  dispatch({
                    type: 'SET_FORM_VALUE',
                    payload: { srcLang: e.currentTarget.value }
                  })
                }
                value={state.formData.srcLang}
                data-testid="src-lang"
              >
                <option value="">{t('指定なし')}</option>
                <option value="de-DE">{t('German')}</option>
                <option value="en-US">{t('English (US)')}</option>
                <option value="es-MX">{t('Spanish (Mexico)')}</option>
                <option value="fr-FR">{t('French')}</option>
                <option value="it-IT">{t('Italian')}</option>
                <option value="ja-JP">{t('Japanese')}</option>
                <option value="ko-KR">{t('Korean')}</option>
                <option value="zh-CN">{t('Chinese (Simplified)')}</option>
                <option value="zh-TW">{t('Chinese (Taiwan)')}</option>
                <option value="th-TH">{t('Thai')}</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={4}>
              {t('ターゲット言語')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                className="formTableCell"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>): void =>
                  dispatch({
                    type: 'SET_FORM_VALUE',
                    payload: { tgtLang: e.currentTarget.value }
                  })
                }
                value={state.formData.tgtLang}
                data-testid="tgt-lang"
              >
                <option value="">{t('指定なし')}</option>
                <option value="de-DE">{t('German')}</option>
                <option value="en-US">{t('English (US)')}</option>
                <option value="es-MX">{t('Spanish (Mexico)')}</option>
                <option value="fr-FR">{t('French')}</option>
                <option value="it-IT">{t('Italian')}</option>
                <option value="ja-JP">{t('Japanese')}</option>
                <option value="ko-KR">{t('Korean')}</option>
                <option value="zh-CN">{t('Chinese (Simplified)')}</option>
                <option value="zh-TW">{t('Chinese (Taiwan)')}</option>
                <option value="th-TH">{t('Thai')}</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={4}>
              {t('ステータス')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                className="formTableCell"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>): void =>
                  dispatch({
                    type: 'SET_FORM_VALUE',
                    payload: { status: e.currentTarget.value }
                  })
                }
                value={state.formData.status}
                data-testid="status"
              >
                <option value="">{t('指定なし')}</option>
                <option value="prepared">{t('準備処理完了')}</option>
                <option value="quote_requested">{t('見積依頼中')}</option>
                <option value="quote_ready">{t('見積提出済み')}</option>
                <option value="order_confirmed">{t('発注確定')}</option>
                <option value="translated">{t('翻訳済み')}</option>
                <option value="reviewed">{t('レビュー済み')}</option>
                <option value="completed">{t('完了')}</option>
                <option value="error">{t('エラー')}</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="vendorId">
            <Form.Label column sm={4}>
              {t('発注先翻訳会社')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                className="formTableCell"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>): void =>
                  dispatch({
                    type: 'SET_FORM_VALUE',
                    payload: { vendorId: e.currentTarget.value }
                  })
                }
                value={state.formData.vendorId}
                data-testid="vendor-id"
              >
                <option value="">{t('指定なし')}</option>
                {vendors
                  ?.filter((vendor: Vendor) => {
                    if (loginUser?.organizationType === 'cistate') {
                      return true
                    }
                    return loginUser?.organizationIds.includes(vendor.ownerGroupId)
                  })
                  .map((vendor: Vendor) => {
                    return (
                      <option value={vendor._id} key={vendor._id}>
                        {`${vendor.name} (${vendor.srcLang} -> ${vendor.tgtLang})`}
                      </option>
                    )
                  })}
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="ownerId">
            <Form.Label column sm={4}>
              {t('担当者')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                className="formTableCell"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                  dispatch({
                    type: 'SET_FORM_VALUE',
                    payload: { ownerId: e.currentTarget.value }
                  })
                }}
                value={state.formData.ownerId}
                data-testid="owner-id"
              >
                <option value="">{t('指定なし')}</option>
                {state.users
                  .filter((user: User) => {
                    return user.organizationType !== 'vendor'
                  })
                  .map((user: User) => {
                    return (
                      <option value={user._id} key={user._id}>
                        {user.username}
                      </option>
                    )
                  })}
              </Form.Control>
            </Col>
          </Form.Group>
          {loginUser?.organizationType === 'cistate' && (
            <Form.Group as={Row} controlId="ownerGroupId">
              <Form.Label column sm={4}>
                {t('担当者所属')}:
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  as="select"
                  className="formTableCell"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>): void =>
                    dispatch({
                      type: 'SET_FORM_VALUE',
                      payload: { ownerGroupId: e.currentTarget.value }
                    })
                  }
                  value={state.formData.ownerGroupId}
                  data-testid="owner-group-id"
                >
                  <option value="">{t('指定なし')}</option>
                  {ownerGroups
                    ?.filter(ownerGroup => {
                      if (loginUser?.organizationType === 'cistate') {
                        return true
                      }
                      return loginUser?.organizationIds.includes(ownerGroup._id)
                    })
                    .map((ownerGroup: OwnerGroup) => {
                      return (
                        <option value={ownerGroup._id} key={ownerGroup._id}>
                          {ownerGroup.name}
                        </option>
                      )
                    })}
                </Form.Control>
              </Col>
            </Form.Group>
          )}
          <Form.Group as={Row}>
            <Form.Label column sm={4}>
              {t('作成日時')}:
            </Form.Label>
            <Col sm="auto">
              <DatePicker
                dateFormat="yyyy-MM-dd HH:mm"
                onChange={(date): void => {
                  dispatch({
                    type: 'SET_FORM_VALUE',
                    payload: { createdAtFrom: date }
                  })
                }}
                selected={state.formData.createdAtFrom}
                showTimeSelect
                customInput={<input data-testid="created-at-from" type="text" />}
              />
            </Col>
            <Col sm="auto">{' 〜 '}</Col>
            <Col sm="auto">
              <DatePicker
                dateFormat="yyyy-MM-dd HH:mm"
                onChange={(date): void => {
                  dispatch({
                    type: 'SET_FORM_VALUE',
                    payload: { createdAtTo: date }
                  })
                }}
                selected={state.formData.createdAtTo}
                showTimeSelect
                customInput={<input data-testid="created-at-to" type="text" />}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={4}>
              {t('アーカイブ済みプロジェクト')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Check id="show-archived-projects-checkbox">
                <Form.Check.Input
                  checked={state.formData.showArchived}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    dispatch({
                      type: 'SET_FORM_VALUE',
                      payload: { showArchived: e.currentTarget.checked }
                    })
                  }}
                  data-testid="show-archived"
                />
                <Form.Check.Label>{t('表示する')}</Form.Check.Label>
              </Form.Check>
            </Col>
          </Form.Group>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}
          >
            <Button onClick={clearFilters} variant="light">
              {t('絞り込み条件をクリア')}
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" data-testid="project-filters-button" type="submit">
            {t('OK')}
          </Button>
          <Button onClick={(): void => props.close()} variant="secondary">
            {t('キャンセル')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { close } = props
  const { state, dispatch, handleSubmit: setFilters } = useProjectFiltersDialog(
    props.location.search
  )

  const onKeyPress = (e: React.KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === 'Enter' /* Enter */) {
      e.preventDefault()
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    setFilters()
    close()
  }

  return (
    <Component
      {...props}
      close={close}
      state={state}
      dispatch={dispatch}
      onKeyPress={onKeyPress}
      handleSubmit={handleSubmit}
    />
  )
}

Container.displayName = 'ProjectFiltersDialog'
export default withRouter(Container)
