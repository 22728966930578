import React from 'react'
import { Button, Modal, Form, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import User from '../../../lib/User'
import OwnerGroup from '../../../lib/OwnerGroup'
import OwnerGrouplist from '../../OwnerGroupList'
import Vendor from '../../../lib/Vendor'
import VendorList from '../../VendorList'
import { OverlayTriggerButton } from '../../common/OverlayTriggerButton'
import useEditUserDialog, { State, Action } from './useEditUserDialog'
import { BackendError } from '../../../lib/BackendError'

type ContainerProps = {
  close: (updated: boolean) => void
  user?: User
}

type Props = ContainerProps & {
  state: State
  dispatch: React.Dispatch<Action>
  onKeyPress: (e: React.KeyboardEvent<HTMLFormElement>) => void
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
}

export const Component: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { close, state, dispatch, handleSubmit, onKeyPress } = props

  const updateSelection = (selected: OwnerGroup[] | Vendor[]): void => {
    dispatch({
      type: 'SET_FORM_VALUE',
      payload: {
        organizationIds: (selected as Array<OwnerGroup | Vendor>).map(
          (item: OwnerGroup | Vendor) => {
            return item._id
          }
        )
      }
    })
    return
  }

  return (
    <Modal onHide={close} show={true} size="lg" data-testid="edit-user-dialog">
      <Form onKeyPress={onKeyPress} onSubmit={handleSubmit}>
        <Modal.Header closeButton>{t('ユーザー')}</Modal.Header>
        <Modal.Body>
          <Form.Group as={Row} controlId="email">
            <Form.Label column sm={4}>
              {t('メールアドレス')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                readOnly={props.user ? true : false}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  dispatch({
                    type: 'SET_FORM_VALUE',
                    payload: {
                      email: e.currentTarget.value
                    }
                  })
                }
                value={state.formData.email}
                required
                type="email"
                data-testid="input-email"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="username">
            <Form.Label column sm={4}>
              {t('ユーザー名')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                readOnly={props.user ? true : false}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  dispatch({
                    type: 'SET_FORM_VALUE',
                    payload: {
                      username: e.currentTarget.value
                    }
                  })
                }
                value={state.formData.username}
                required
                data-testid="input-username"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="organizationType">
            <Form.Label column sm={4}>
              {t('所属先区分')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Check
                id="radio-misumi"
                type="radio"
                label="MISUMI"
                inline
                checked={state.formData.organizationType === 'owner-group'}
                name="owner-group"
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  dispatch({
                    type: 'SET_FORM_VALUE',
                    payload: {
                      organizationType: e.currentTarget.value,
                      organizationIds: []
                    }
                  })
                }}
                value="owner-group"
                data-testid="select-organization-type-misumi"
              />
              <Form.Check
                id="radio-vendor"
                type="radio"
                label="VENDOR"
                inline
                checked={state.formData.organizationType === 'vendor'}
                name="vendor"
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  dispatch({
                    type: 'SET_FORM_VALUE',
                    payload: {
                      organizationType: e.currentTarget.value,
                      organizationIds: []
                    }
                  })
                }}
                value="vendor"
                data-testid="select-organization-type-vendor"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="organizationId">
            <Form.Label column sm={4}>
              {t('所属先名')}:
            </Form.Label>
            <Col sm={8}>
              <div style={{ height: '30vh' }}>
                {state.formData.organizationType === 'owner-group' && (
                  <OwnerGrouplist
                    selected={state.formData.organizationIds ? state.formData.organizationIds : []}
                    updateSelection={(selected: OwnerGroup[]): void => {
                      updateSelection(selected)
                    }}
                    visible={true}
                  />
                )}
                {state.formData.organizationType === 'vendor' && (
                  <VendorList
                    selected={state.formData.organizationIds ? state.formData.organizationIds : []}
                    updateSelection={(selected: Vendor[]): void => {
                      updateSelection(selected)
                    }}
                    visible={true}
                  />
                )}
              </div>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="role">
            <Form.Label column sm={4}>
              {t('ロール')}:
            </Form.Label>
            <Col sm="auto">
              <Form.Control
                as="select"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>): void =>
                  dispatch({ type: 'SET_FORM_VALUE', payload: { role: e.currentTarget.value } })
                }
                value={state.formData.role}
                data-testid="select-role"
              >
                <option value="user">{t('一般ユーザー')}</option>
                <option value="admin">{t('管理者')}</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="sendNotifications">
            <Form.Label column sm={4}>
              {t('通知メール')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Check
                checked={state.formData.sendNotifications === true}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  dispatch({
                    type: 'SET_FORM_VALUE',
                    payload: { sendNotifications: e.currentTarget.checked }
                  })
                }}
                data-testid="check-send-notifications"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="isEnabled">
            <Form.Label column sm={4}>
              {t('有効')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Check
                checked={state.formData.isEnabled === true}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  dispatch({
                    type: 'SET_FORM_VALUE',
                    payload: { isEnabled: e.currentTarget.checked }
                  })
                }}
                data-testid="check-is-enabled"
              />
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <span style={state.message.isError ? { color: 'red' } : undefined}>
            {state.message.text}
          </span>
          <OverlayTriggerButton
            tooltipLabel={t('処理中')}
            buttonLabel={t('OK')}
            isOverlayed={(): boolean => state.loading}
            isLoading={(): boolean => state.loading}
            tooltipId="tooltip-edit-user-in-progress"
            variant="primary"
            type="submit"
            data-testid="submit-button"
          />
          <Button
            disabled={state.loading}
            onClick={(): void => close(false)}
            style={state.loading ? { pointerEvents: 'none' } : {}}
            variant="secondary"
          >
            {t('キャンセル')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { user } = props
  const { state, dispatch, handleSubmit: submitUser } = useEditUserDialog(user)

  const close = (updated: boolean): void => {
    if (!state.loading) {
      props.close(updated)
    }
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === 'Enter' /* Enter */) {
      e.preventDefault()
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    try {
      await submitUser()
      close(true)
    } catch (error) {
      if (error.response) {
        const errorInfo: BackendError = error.response.data
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: errorInfo.message, isError: true }
        })
      } else {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: error.message, isError: true }
        })
      }
    } finally {
      dispatch({ type: 'SET_IS_LOADING', payload: { isLoading: false } })
    }
  }

  return (
    <Component
      {...props}
      close={close}
      state={state}
      dispatch={dispatch}
      handleSubmit={handleSubmit}
      onKeyPress={onKeyPress}
    />
  )
}

Container.displayName = 'EditUserDialog'
export default Container
