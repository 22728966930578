export default {
  STAGE: process.env.REACT_APP_ENV || 'development',
  development: {
    Auth: {
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      region: process.env.REACT_APP_REGION,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
    },
    endpoint: process.env.REACT_APP_ENDPOINT,
    editorUrl: process.env.REACT_APP_EDITOR_URL
  },
  staging: {
    Auth: {
      identityPoolId: '',
      region: 'ap-northeast-1',
      userPoolId: 'ap-northeast-1_OCZtVM8qf',
      userPoolWebClientId: '6iqljte9sng3kc4fj1bunkj71n'
    },
    endpoint: 'https://api-misumi-stg.translation-dashboard.com',
    editorUrl: 'https://editor-misumi-stg.translation-dashboard.com'
  },
  qa: {
    Auth: {
      identityPoolId: '',
      region: 'ap-northeast-1',
      userPoolId: 'ap-northeast-1_PKiSBnE5v',
      userPoolWebClientId: 'eql5e1h405r1pj0qqaaqg4in9'
    },
    endpoint: 'https://api-misumi-qa.translation-dashboard.com',
    editorUrl: ''
  },
  production: {
    Auth: {
      identityPoolId: '',
      region: 'ap-northeast-1',
      userPoolId: 'ap-northeast-1_vnwFTLmKg',
      userPoolWebClientId: '1gbvdbqc0h6e61peum41qu6fdh'
    },
    endpoint: 'https://api-misumi.translation-dashboard.com',
    editorUrl: 'https://editor-misumi.translation-dashboard.com'
  },
  test: {
    Auth: {
      identityPoolId: '',
      region: '',
      userPoolId: '',
      userPoolWebClientId: ''
    },
    endpoint: '',
    editorUrl: ''
  }
}
