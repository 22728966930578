import React from 'react'
import { Button, Modal, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import Project from '../../../lib/Project'
import JobList from '../../JobList'
import useDownloadTranslatedFilesDialog, { State, Action } from './useDownloadTranslatedFilesDialog'

type ContainerProps = {
  close: Function
  project: Project
  title: string
}

type Props = ContainerProps & {
  state: State
  dispatch: React.Dispatch<Action>
}

export const Component: React.FC<Props> = (props: Props) => {
  const { state } = props
  const { t } = useTranslation()

  return (
    <Modal onHide={(): void => props.close()} show={true} size="lg">
      <Form>
        <Modal.Header closeButton>{props.title}</Modal.Header>
        <Modal.Body style={{ height: '70vh', marginBottom: '20px' }}>
          <div style={{ marginBottom: '20px' }}>
            {t('翻訳済みジョブ数')}:{' '}
            <span data-testid="translated-jobs-count">{state.jobsCount.translated}</span>/
            <span data-testid="total-jobs-count">{state.jobsCount.total}</span>
            <Button
              className={'float-right'}
              variant="info"
              size="sm"
              disabled={state.projectDownloadUrl ? !state.projectDownloadUrl.exists : true}
              href={state.projectDownloadUrl ? state.projectDownloadUrl.presignedUrl : ''}
              data-testid="download-translated-files-button"
            >
              {t('翻訳済みのジョブをまとめてダウンロード')}
            </Button>
          </div>
          <JobList
            options={`projectId=${props.project._id}&[translatedAt][$ne]=null`}
            showDownloadButtonColumn={false}
            showProjectInfoColumns={false}
            showJobNameAsLink={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={(): void => props.close()} variant="primary">
            {t('閉じる')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { close, project } = props
  const { state, dispatch } = useDownloadTranslatedFilesDialog(project)

  return <Component {...props} close={close} state={state} dispatch={dispatch} />
}

Container.displayName = 'DownloadTranslatedFilesDialog'
export default Container
