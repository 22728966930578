import React, { createRef, forwardRef, useImperativeHandle, useState } from 'react'
import { ICellEditorParams } from 'ag-grid-community'

import Vendor from '../../../lib/Vendor'

type Params = ICellEditorParams & {
  values: Vendor[]
}

const VendorCellEditor = forwardRef(
  (params: Params, ref): JSX.Element => {
    const selectRef = createRef<HTMLSelectElement>()

    const [value, setValue] = useState(params.value)

    useImperativeHandle(ref, () => {
      return {
        getValue: (): string => {
          return selectRef.current ? selectRef.current.value : ''
        }
      }
    })

    return (
      <>
        {params.data.editable ? (
          <select
            onChange={(e): void => {
              setValue(e.currentTarget.value)
            }}
            style={{
              width: '100%'
            }}
            value={value}
            ref={selectRef}
          >
            <option value=""></option>
            {params.values
              .filter((vendor: Vendor) => {
                return (
                  vendor.srcLang === params.data.srcLang && vendor.tgtLang === params.data.tgtLang
                )
              })
              .filter((vendor: Vendor) => {
                if (params.data.ownerGroupId !== '') {
                  return vendor.ownerGroupId === params.data.ownerGroupId
                }
                return true
              })
              .map((vendor: Vendor) => {
                return (
                  <option key={vendor._id} value={vendor._id}>
                    {params.formatValue(vendor._id)}
                  </option>
                )
              })}
          </select>
        ) : (
          <span className="ag-cell-value" style={{ textOverflow: 'hidden' }}>
            {params.formatValue(params.value)}
          </span>
        )}
      </>
    )
  }
)
VendorCellEditor.displayName = 'VendorCellEditor'

export default VendorCellEditor
