import React from 'react'
import { Button, Modal, Form, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Decimal } from 'decimal.js'

import OwnerGroup from '../../../lib/OwnerGroup'
import Vendor from '../../../lib/Vendor'
import { OverlayTriggerButton } from '../../common/OverlayTriggerButton'
import useEditVendorDialog, { State, Action } from './useEditVendorDialog'
import { BackendError } from '../../../lib/BackendError'

type ContainerProps = {
  close: (updated: boolean) => void
  vendor?: Vendor
}

type Props = ContainerProps & {
  state: State
  dispatch: React.Dispatch<Action>
  onKeyPress: (e: React.KeyboardEvent<HTMLFormElement>) => void
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
}

export const Component: React.FC<Props> = (props: Props) => {
  const { close: closeDialog, handleSubmit, onKeyPress, state, dispatch } = props
  const { t } = useTranslation()

  return (
    <Modal onHide={closeDialog} show={true} size="lg">
      <Form onKeyPress={onKeyPress} onSubmit={handleSubmit}>
        <Modal.Header closeButton>{t('翻訳会社')}</Modal.Header>
        <Modal.Body>
          <Form.Group as={Row} controlId="name">
            <Form.Label column sm={3}>
              {t('翻訳会社名称')}:
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  dispatch({ type: 'SET_FORM_VALUE', payload: { name: e.currentTarget.value } })
                }}
                value={state.formData.name}
                required
                data-testid="input-name"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="ownerGroupId">
            <Form.Label column sm={3}>
              {t('所属先')}:
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                as="select"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>): void =>
                  dispatch({
                    type: 'SET_FORM_VALUE',
                    payload: { ownerGroupId: e.currentTarget.value }
                  })
                }
                value={state.formData.ownerGroupId ? state.formData.ownerGroupId : ''}
                required
                data-testid="select-owner-group-id"
              >
                {state.ownerGroups?.map((ownerGroup: OwnerGroup) => {
                  return (
                    <option value={ownerGroup._id} key={ownerGroup._id}>
                      {ownerGroup.name}
                    </option>
                  )
                })}
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="organizationType">
            <Form.Label column sm={3}>
              {t('ソース言語')}:
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                as="select"
                className="formTableCell"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>): void =>
                  dispatch({ type: 'SET_FORM_VALUE', payload: { srcLang: e.currentTarget.value } })
                }
                required
                value={state.formData.srcLang}
                data-testid="select-src-lang"
              >
                <option value="de-DE">{t('German')}</option>
                <option value="en-US">{t('English (US)')}</option>
                <option value="es-MX">{t('Spanish (Mexico)')}</option>
                <option value="fr-FR">{t('French')}</option>
                <option value="it-IT">{t('Italian')}</option>
                <option value="ja-JP">{t('Japanese')}</option>
                <option value="ko-KR">{t('Korean')}</option>
                <option value="zh-CN">{t('Chinese (Simplified)')}</option>
                <option value="zh-TW">{t('Chinese (Taiwan)')}</option>
                <option value="th-TH">{t('Thai')}</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="organizationId">
            <Form.Label column sm={3}>
              {t('ターゲット言語')}:
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                as="select"
                className="formTableCell"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>): void =>
                  dispatch({ type: 'SET_FORM_VALUE', payload: { tgtLang: e.currentTarget.value } })
                }
                required
                value={state.formData.tgtLang}
                data-testid="select-tgt-lang"
              >
                <option value="de-DE">{t('German')}</option>
                <option value="en-US">{t('English (US)')}</option>
                <option value="es-MX">{t('Spanish (Mexico)')}</option>
                <option value="fr-FR">{t('French')}</option>
                <option value="it-IT">{t('Italian')}</option>
                <option value="ja-JP">{t('Japanese')}</option>
                <option value="ko-KR">{t('Korean')}</option>
                <option value="zh-CN">{t('Chinese (Simplified)')}</option>
                <option value="zh-TW">{t('Chinese (Taiwan)')}</option>
                <option value="th-TH">{t('Thai')}</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="unitPrice">
            <Form.Label column sm={3}>
              {t('翻訳標準単価')}:
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="number"
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  if (e.currentTarget.value) {
                    dispatch({
                      type: 'SET_FORM_VALUE',
                      payload: { unitPrice: parseFloat(e.currentTarget.value) }
                    })
                  }
                }}
                required
                value={state.formData.unitPrice}
                data-testid="input-unit-price"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="currency">
            <Form.Label column sm={3}>
              {t('通貨')}:
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  dispatch({ type: 'SET_FORM_VALUE', payload: { currency: e.currentTarget.value } })
                }}
                required
                value={state.formData.currency}
                data-testid="input-currency"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="multipliers">
            <Form.Label column sm={3}>
              {t('係数')}:
            </Form.Label>
            <Col sm={9}>
              <Form.Group as={Row} controlId="repetitions">
                <Form.Label column sm={3}>
                  {t('繰り返し')}
                </Form.Label>
                <Col sm={6}>
                  <Form.Control
                    type="number"
                    max="1"
                    min="0"
                    step="0.01"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                      if (e.currentTarget.value) {
                        dispatch({
                          type: 'SET_FORM_VALUE',
                          payload: {
                            multipliers: {
                              ...state.formData.multipliers,
                              repetitions: parseFloat(e.currentTarget.value)
                            }
                          }
                        })
                      }
                    }}
                    required
                    value={state.formData.multipliers.repetitions}
                    data-testid="input-multipliers-repetitions"
                  />
                </Col>
                <Col sm={3}>{` = ${new Decimal(state.formData.unitPrice).times(
                  state.formData.multipliers.repetitions
                )} ${state.formData.currency}`}</Col>
              </Form.Group>
              <Form.Group as={Row} controlId="exact">
                <Form.Label column sm={3}>
                  {t('100%一致')}
                </Form.Label>
                <Col sm={6}>
                  <Form.Control
                    type="number"
                    max="1"
                    min="0"
                    step="0.01"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                      if (e.currentTarget.value) {
                        dispatch({
                          type: 'SET_FORM_VALUE',
                          payload: {
                            multipliers: {
                              ...state.formData.multipliers,
                              exact: parseFloat(e.currentTarget.value)
                            }
                          }
                        })
                      }
                    }}
                    required
                    value={state.formData.multipliers.exact}
                    data-testid="input-multipliers-exact"
                  />
                </Col>
                <Col sm={3}>{` = ${new Decimal(state.formData.unitPrice).times(
                  state.formData.multipliers.exact
                )} ${state.formData.currency}`}</Col>
              </Form.Group>
              <Form.Group as={Row} controlId="over95">
                <Form.Label column sm={3}>
                  {t('95% - 99%')}
                </Form.Label>
                <Col sm={6}>
                  <Form.Control
                    type="number"
                    max="1"
                    min="0"
                    step="0.01"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                      if (e.currentTarget.value) {
                        dispatch({
                          type: 'SET_FORM_VALUE',
                          payload: {
                            multipliers: {
                              ...state.formData.multipliers,
                              over95: parseFloat(e.currentTarget.value)
                            }
                          }
                        })
                      }
                    }}
                    required
                    value={state.formData.multipliers.over95}
                    data-testid="input-multipliers-over95"
                  />
                </Col>
                <Col sm={3}>{` = ${new Decimal(state.formData.unitPrice).times(
                  state.formData.multipliers.over95
                )} ${state.formData.currency}`}</Col>
              </Form.Group>
              <Form.Group as={Row} controlId="over85">
                <Form.Label column sm={3}>
                  {t('85% - 94%')}
                </Form.Label>
                <Col sm={6}>
                  <Form.Control
                    type="number"
                    max="1"
                    min="0"
                    step="0.01"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                      if (e.currentTarget.value) {
                        dispatch({
                          type: 'SET_FORM_VALUE',
                          payload: {
                            multipliers: {
                              ...state.formData.multipliers,
                              over85: parseFloat(e.currentTarget.value)
                            }
                          }
                        })
                      }
                    }}
                    required
                    value={state.formData.multipliers.over85}
                    data-testid="input-multipliers-over85"
                  />
                </Col>
                <Col sm={3}>{` = ${new Decimal(state.formData.unitPrice).times(
                  state.formData.multipliers.over85
                )} ${state.formData.currency}`}</Col>
              </Form.Group>
              <Form.Group as={Row} controlId="over75">
                <Form.Label column sm={3}>
                  {t('75% - 84%')}
                </Form.Label>
                <Col sm={6}>
                  <Form.Control
                    type="number"
                    max="1"
                    min="0"
                    step="0.01"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                      if (e.currentTarget.value) {
                        dispatch({
                          type: 'SET_FORM_VALUE',
                          payload: {
                            multipliers: {
                              ...state.formData.multipliers,
                              over75: parseFloat(e.currentTarget.value)
                            }
                          }
                        })
                      }
                    }}
                    required
                    value={state.formData.multipliers.over75}
                    data-testid="input-multipliers-over75"
                  />
                </Col>
                <Col sm={3}>{` = ${new Decimal(state.formData.unitPrice).times(
                  state.formData.multipliers.over75
                )} ${state.formData.currency}`}</Col>
              </Form.Group>
              <Form.Group as={Row} controlId="noMatch">
                <Form.Label column sm={3}>
                  {t('一致なし')}
                </Form.Label>
                <Col sm={6}>
                  <Form.Control
                    type="number"
                    step="0.01"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                      if (e.currentTarget.value) {
                        dispatch({
                          type: 'SET_FORM_VALUE',
                          payload: {
                            multipliers: {
                              ...state.formData.multipliers,
                              noMatch: parseFloat(e.currentTarget.value)
                            }
                          }
                        })
                      }
                    }}
                    required
                    value={state.formData.multipliers.noMatch}
                    data-testid="input-multipliers-no-match"
                  />
                </Col>
                <Col sm={3}>{` = ${new Decimal(state.formData.unitPrice).times(
                  state.formData.multipliers.noMatch
                )} ${state.formData.currency}`}</Col>
              </Form.Group>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="isEnabled">
            <Form.Label column sm={3}>
              {t('有効')}:
            </Form.Label>
            <Col sm={9}>
              <Form.Check
                checked={state.formData.isEnabled === true}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  dispatch({
                    type: 'SET_FORM_VALUE',
                    payload: { ...state.formData, isEnabled: e.currentTarget.checked }
                  })
                }}
                data-testid="check-is-enabled"
              />
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <span style={state.message.isError ? { color: 'red' } : undefined}>
            {state.message.text}
          </span>
          <OverlayTriggerButton
            tooltipLabel={t('処理中')}
            buttonLabel={t('OK')}
            isOverlayed={(): boolean => state.loading}
            isLoading={(): boolean => state.loading}
            tooltipId="tooltip-edit-vendor-in-progress"
            variant="primary"
            type="submit"
            data-testid="submit-button"
          />
          <Button
            disabled={state.loading}
            onClick={(): void => closeDialog(false)}
            style={state.loading ? { pointerEvents: 'none' } : {}}
            variant="secondary"
          >
            {t('キャンセル')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { vendor } = props
  const { state, dispatch, handleSubmit: submitVendor } = useEditVendorDialog(vendor)

  const close = (updated: boolean): void => {
    if (!state.loading) {
      props.close(updated)
    }
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === 'Enter' /* Enter */) {
      e.preventDefault()
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    try {
      await submitVendor()
      close(true)
    } catch (error) {
      if (error.response) {
        const errorInfo: BackendError = error.response.data
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: errorInfo.message, isError: true }
        })
      } else {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: error.message, isError: true }
        })
      }
    } finally {
      dispatch({ type: 'SET_IS_LOADING', payload: { isLoading: false } })
    }
  }

  return (
    <Component
      {...props}
      close={close}
      state={state}
      dispatch={dispatch}
      handleSubmit={handleSubmit}
      onKeyPress={onKeyPress}
    />
  )
}

Container.displayName = 'EditVendorDialog'
export default Container
