import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'

import { useFilters } from './useFilters'
import OwnerGroup from '../../../lib/OwnerGroup'

interface Props {
  ownerGroups: OwnerGroup[]
}

const DetailedConditions: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  const { state, dispatch } = useFilters()

  return (
    <>
      <Row>
        <Col sm={4}>
          <Form.Group as={Row} controlId="confidence">
            <Form.Label column sm={4}>
              {t('信頼度')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  dispatch({
                    type: 'UPDATE',
                    payload: {
                      ...state,
                      confidence: e.currentTarget.value
                    }
                  })
                }
                pattern="^100$|^[0-9]{1,2}$"
                value={state.confidence}
              ></Form.Control>
              <Form.Text>{t('0〜100')}</Form.Text>
            </Col>
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group as={Row}>
            <Form.Label column sm={4}>
              {t('所属先名')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>): void =>
                  dispatch({
                    type: 'UPDATE',
                    payload: {
                      ...state,
                      ownerGroupId: e.currentTarget.value
                    }
                  })
                }
                value={state.ownerGroupId}
              >
                <option value="">({t('指定なし')})</option>
                {props.ownerGroups.map(ownerGroup => {
                  return (
                    <option key={ownerGroup._id} value={ownerGroup._id}>
                      {ownerGroup.name}
                    </option>
                  )
                })}
              </Form.Control>
            </Col>
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group as={Row}>
            <Form.Label column sm={4}>
              {t('カテゴリ')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>): void =>
                  dispatch({
                    type: 'UPDATE',
                    payload: {
                      ...state,
                      category: e.currentTarget.value
                    }
                  })
                }
                value={state.category}
              >
                <option value="">({t('指定なし')})</option>
                <option value="null">{t('カテゴリなし')}</option>
                <option value="E">E</option>
                <option value="M">M</option>
                <option value="T">T</option>
                <option value="_">_</option>
              </Form.Control>
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <Form.Group as={Row}>
            <Form.Label column sm={4}>
              {t('インポート元TM名')}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  dispatch({
                    type: 'UPDATE',
                    payload: {
                      ...state,
                      tmName: e.currentTarget.value
                    }
                  })
                }
                value={state.tmName}
              />
              <Form.Text>{t('部分一致 (大文字小文字を区別しない)')}</Form.Text>
            </Col>
          </Form.Group>
        </Col>
        <Col sm={8}>
          <Form.Group as={Row}>
            <Form.Label column sm={2}>
              {t('作成日時')}:
            </Form.Label>
            <Col sm="auto">
              <DatePicker
                className="form-control"
                dateFormat="yyyy-MM-dd HH:mm"
                onChange={(date): void =>
                  dispatch({
                    type: 'UPDATE',
                    payload: {
                      ...state,
                      createdAtFrom: date
                    }
                  })
                }
                selected={state.createdAtFrom}
                showTimeSelect
              />
            </Col>
            <Col sm="auto">{' 〜 '}</Col>
            <Col sm="auto">
              <DatePicker
                className="form-control"
                dateFormat="yyyy-MM-dd HH:mm"
                onChange={(date): void =>
                  dispatch({
                    type: 'UPDATE',
                    payload: {
                      ...state,
                      createdAtTo: date
                    }
                  })
                }
                selected={state.createdAtTo}
                showTimeSelect
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}

export default DetailedConditions
