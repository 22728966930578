import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

import config from '../../config'

import Job from '../../lib/Job'
import Project from '../../lib/Project'

import { CognitoUserContext } from '../../App'
import { useProjectDetailPage } from './useProjectDetailPage'

import CreateTranslationKitDialog from '../../components/dialogs/CreateTranslationKitDialog'
import DownloadTranslatedFilesDialog from '../../components/dialogs/DownloadTranslatedFilesDialog'
import JobFiltersDialog from '../../components/dialogs/JobFiltersDialog'
import OKCancelDialog from '../../components/dialogs/OKCancelDialog'
import QuotedAmountDialog from '../../components/dialogs/QuotedAmountDialog'
import RemoveJobsDialog from '../../components/dialogs/RemoveJobsDialog'
import SelectJobsDialog from '../../components/dialogs/SelectJobsDialog'
import SendToMdmDialog from '../../components/dialogs/SendToMdmDialog'
import UploadTranslatedXliffsDialog from '../../components/dialogs/UploadTranslatedXliffsDialog'

interface Props {
  project: Project
  refreshJobList: Function
  selectedJobs: Job[]
}

const Dialogs: React.FC<Props> = (props: Props) => {
  const cognitoUser = useContext(CognitoUserContext)

  const { t } = useTranslation()

  const { state, dispatch } = useProjectDetailPage()

  const projectId = props.project._id

  const approveOrder = async (): Promise<void> => {
    await axios.post(
      config[config.STAGE].endpoint + '/api/v1/projects/' + projectId + '/approveOrder',
      {}
    )
  }

  const cancelProject = async (): Promise<void> => {
    await axios.post(
      config[config.STAGE].endpoint + '/api/v1/projects/' + projectId + '/cancelProject',
      {}
    )
  }

  const returnSourceToMdm = async (): Promise<void> => {
    await axios.post(
      config[config.STAGE].endpoint + '/api/v1/projects/' + projectId + '/returnSourceToMdm',
      {}
    )
  }

  return (
    <>
      {state.showReturnSourceToMdmDialog && (
        <OKCancelDialog
          body={
            <p>
              {t('ソース戻し実行者')}: {cognitoUser.attributes.email}
            </p>
          }
          close={(updated: boolean): void => {
            dispatch({
              type: 'UPDATE',
              payload: {
                ...state,
                showReturnSourceToMdmDialog: false
              }
            })
            if (updated) {
              props.refreshJobList()
            }
          }}
          onClickOK={returnSourceToMdm}
          title={t('ソース戻し')}
        />
      )}
      {state.showCancelProjectDialog && (
        <OKCancelDialog
          body={
            <p>
              {t('キャンセル実行者')}: {cognitoUser.attributes.email}
            </p>
          }
          close={(updated: boolean): void => {
            dispatch({
              type: 'UPDATE',
              payload: {
                ...state,
                showCancelProjectDialog: false
              }
            })
            if (updated) {
              props.refreshJobList()
            }
          }}
          onClickOK={cancelProject}
          title={t('発注キャンセル')}
        />
      )}
      {state.showApproveOrderDialog && (
        <OKCancelDialog
          body={
            <p>
              {t('発注承認者')}: {cognitoUser.attributes.email}
            </p>
          }
          close={(updated: boolean): void => {
            dispatch({
              type: 'UPDATE',
              payload: {
                ...state,
                showApproveOrderDialog: false
              }
            })
            if (updated) {
              props.refreshJobList()
            }
          }}
          onClickOK={approveOrder}
          title={t('発注承認')}
        />
      )}
      {state.showQuotedAmountDialog && (
        <QuotedAmountDialog
          close={(updated: boolean): void => {
            dispatch({
              type: 'UPDATE',
              payload: {
                ...state,
                showQuotedAmountDialog: false
              }
            })
            if (updated) {
              props.refreshJobList()
            }
          }}
          project={props.project}
        />
      )}
      {state.showRemoveJobsDialog && (
        <RemoveJobsDialog
          close={(updated: boolean): void => {
            dispatch({
              type: 'UPDATE',
              payload: {
                ...state,
                showRemoveJobsDialog: false
              }
            })
            if (updated) {
              props.refreshJobList()
            }
          }}
          jobs={props.selectedJobs}
        />
      )}
      {state.showSelectJobsDialog && (
        <SelectJobsDialog
          close={(updated: boolean): void => {
            dispatch({
              type: 'UPDATE',
              payload: {
                ...state,
                showSelectJobsDialog: false
              }
            })
            if (updated) {
              props.refreshJobList()
            }
          }}
          projectId={props.project._id}
          srcLang={props.project.srcLang}
          tgtLang={props.project.tgtLang}
          title={t('ジョブを追加')}
        />
      )}
      {state.showUploadTranslatedXliffDialog && (
        <UploadTranslatedXliffsDialog
          close={(): void => {
            dispatch({
              type: 'UPDATE',
              payload: {
                ...state,
                showUploadTranslatedXliffDialog: false
              }
            })
          }}
          projectId={props.project._id}
          title={t('翻訳済みXLIFFをアップロード')}
        />
      )}
      {state.showDownloadTranslatedFilesDialog && (
        <DownloadTranslatedFilesDialog
          close={(): void => {
            dispatch({
              type: 'UPDATE',
              payload: {
                ...state,
                showDownloadTranslatedFilesDialog: false
              }
            })
          }}
          project={props.project}
          title={t('翻訳済みジョブをダウンロード')}
        />
      )}

      {state.showSendToMdmDialog && (
        <SendToMdmDialog
          close={(updated: boolean): void => {
            dispatch({
              type: 'UPDATE',
              payload: {
                ...state,
                showSendToMdmDialog: false
              }
            })
            if (updated) {
              props.refreshJobList()
            }
          }}
          project={props.project}
          title={t('完了')}
        />
      )}
      {state.showJobFiltersDialog && (
        <JobFiltersDialog
          close={(): void => {
            dispatch({
              type: 'UPDATE',
              payload: {
                ...state,
                showJobFiltersDialog: false
              }
            })
          }}
          project={props.project}
        />
      )}
      {state.showCreateTranslationKitDialog && (
        <CreateTranslationKitDialog
          close={(creating: boolean): void => {
            // creating === true: 翻訳キットを生成中はボタン押下不可
            dispatch({
              type: 'UPDATE',
              payload: {
                ...state,
                canCreateTranslationKit: !creating
              }
            })
            dispatch({
              type: 'UPDATE',
              payload: {
                ...state,
                showCreateTranslationKitDialog: false
              }
            })
          }}
          project={props.project}
        />
      )}
    </>
  )
}

export default Dialogs
