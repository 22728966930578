import React, { useState } from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import Vendor from '../../../lib/Vendor'
import Toolbar from './Toolbar'
import VendorList from '../../../components/VendorList'
import EditVendorDialog from '../../../components/dialogs/EditVendorDialog'

const Vendors: React.FC = () => {
  const { t } = useTranslation()

  const [selectedVendors, setSelectedVendors] = useState<Vendor[]>([])

  const [showAddVendorDialog, setShowAddVendorDialog] = useState(false)
  const [showEditVendorDialog, setShowEditVendorDialog] = useState(false)

  const [refreshVendorList, setRefreshVendorList] = useState(true)

  const updateSelection = (selected: Vendor[]): void => {
    setSelectedVendors(selected)
  }

  return (
    <div className="Page">
      <Breadcrumb>
        <Breadcrumb.Item active>{t('翻訳会社一覧')}</Breadcrumb.Item>
      </Breadcrumb>
      <Toolbar
        selectedVendors={[]}
        showAddVendorDialog={(): void => setShowAddVendorDialog(true)}
      />
      <VendorList
        enableEdit={true}
        showAllColumns
        updateSelection={updateSelection}
        toggleRefresh={refreshVendorList}
      />
      {showAddVendorDialog && (
        <EditVendorDialog
          close={(updated: boolean): void => {
            setShowAddVendorDialog(false)
            if (updated) {
              setRefreshVendorList(!refreshVendorList)
              setSelectedVendors([])
            }
          }}
        />
      )}
      {showEditVendorDialog && (
        <EditVendorDialog
          close={(updated: boolean): void => {
            setShowAddVendorDialog(false)
            if (updated) {
              setRefreshVendorList(!refreshVendorList)
              setSelectedVendors([])
            }
          }}
        />
      )}
    </div>
  )
}

export default Vendors
