import React, { useEffect, useState } from 'react'
import { Button, ButtonToolbar, Dropdown, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import axios from 'axios'
import useAxios from 'axios-hooks'
import queryString from 'query-string'

import config from '../../../config'
import { CenteredSpinner } from '../../../components/common/CenteredSpinner'
import ToggleSortOrderButton from '../../../components/ToggleSortOrderButton'
import useQueryString from '../../../hooks/useQueryString'

interface Props extends RouteComponentProps {
  showEditTMDialog: Function
  showImportTmxDialog: Function
  toggleInlineEditMode: Function
}

const Toolbar: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  const { sortBy, orderByAsc } = useQueryString(props.location.search)

  const [{ data: countData }, countRefetch] = useAxios(
    config[config.STAGE].endpoint + '/api/v1/tmEntries/count' + props.location.search
  )
  const [{ data: totalData }, totalRefetch] = useAxios(
    config[config.STAGE].endpoint + '/api/v1/tmEntries/count'
  )

  const [showExportTmxDialog, setShowExportTmxDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [srcLangNotSpecified, setSrcLangNotSpecified] = useState(false)

  const onChangeSortBy = (value: string): void => {
    const qs = props.location.search
    const queries = queryString.parse(qs)
    delete queries[`options[sort][${sortBy}]`]
    queries[`options[sort][${value}]`] = orderByAsc ? '1' : '-1'

    const options = queryString.stringify(queries)
    props.history.push('/tm-entries?' + options)
  }

  const toggleOrderByAsc = (): void => {
    const qs = props.location.search
    const queries = queryString.parse(qs)
    queries[`options[sort][${sortBy ? sortBy : 'createdAt'}]`] = !orderByAsc ? '1' : '-1'

    const options = queryString.stringify(queries)
    props.history.push('/tm-entries?' + options)
  }

  const exportTmx = async (): Promise<void> => {
    setLoading(true)
    setSrcLangNotSpecified(false)
    const qs = props.location.search
    const queries = queryString.parse(qs)

    setShowExportTmxDialog(true)
    if (!Object.keys(queries).includes('srcLang')) {
      setSrcLangNotSpecified(true)
      setLoading(false)
      return
    }
    await axios.post(`${config[config.STAGE].endpoint}/api/v1/tmEntries/export${qs}`)
    setLoading(false)
    return
  }

  useEffect(() => {
    setSrcLangNotSpecified(false)
    setLoading(false)
    countRefetch()
    totalRefetch()
  }, [countRefetch, props, props.location.search, totalRefetch])

  return (
    <div>
      <ButtonToolbar className="ml-3 mr-3 mb-3">
        <Button onClick={(): void => props.showEditTMDialog()}>{t('TMエントリ追加')}</Button>
        <Dropdown>
          <Dropdown.Toggle className="ml-2" id="import-and-export" variant="secondary">
            {t('インポート / エクスポート')}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={(): void => props.showImportTmxDialog()}>
              {t('TMXインポート')}
            </Dropdown.Item>
            <Dropdown.Item onClick={exportTmx}>{t('TMXエクスポート')}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Button className="ml-2" disabled variant="link">
          {t('インライン編集')}:
        </Button>
        <div className="toggle-switch ml-0 mr-auto" style={{ padding: 9 }}>
          <input
            id="toggle"
            className="toggle-input"
            type="checkbox"
            defaultChecked={false}
            onClick={(): void => props.toggleInlineEditMode()}
          />
          <label htmlFor="toggle" className="toggle-label" />
        </div>
        <Button className="ml-2" disabled variant="link">
          {countData?.count} / {totalData?.count}
        </Button>
        <select
          className="ml-2"
          onChange={(e: React.FormEvent<HTMLSelectElement>): void =>
            onChangeSortBy(e.currentTarget.value)
          }
          value={sortBy ? sortBy : 'createdAt'}
        >
          <option value="createdAt">{t('作成日時')}</option>
          <option value="updatedAt">{t('更新日時')}</option>
          <option value="srcLang">{t('ソース言語')}</option>
          <option value="tgtLang">{t('ターゲット言語')}</option>
          <option value="confidence">{t('信頼度')}</option>
          <option value="properties.category">{t('カテゴリ')}</option>
        </select>
        <ToggleSortOrderButton
          onClick={toggleOrderByAsc}
          orderByAsc={orderByAsc ? orderByAsc : false}
        />
        <Modal show={showExportTmxDialog}>
          <Modal.Header>{t('TMXエクスポート')}</Modal.Header>
          <Modal.Body>
            {loading ? (
              <>
                <p>{t('エクスポートの開始中')}</p>
                <CenteredSpinner />
              </>
            ) : srcLangNotSpecified ? (
              <p>{t('ソース言語が指定されていません。')}</p>
            ) : (
              <p>
                {t('エクスポートを開始しました。')}
                <br />
                {t('完了するとダウンロードリンクがメールで送信されます。')}
              </p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={loading} onClick={(): void => setShowExportTmxDialog(false)}>
              {t('OK')}
            </Button>
          </Modal.Footer>
        </Modal>
      </ButtonToolbar>
    </div>
  )
}

export default withRouter(Toolbar)
