import React from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import Job from '../../../lib/Job'

interface Props {
  className?: string
  jobs: Job[]
  onClick: Function
}

const EditJobsButton: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  return (
    <>
      {props.jobs.length === 0 ? (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-editjobs-disabled">{t('ジョブが選択されていません')}</Tooltip>
          }
        >
          <span className="d-inline-block">
            <Button
              className={props.className}
              disabled
              style={{ pointerEvents: 'none' }}
              variant="secondary"
            >
              {t('選択したジョブを編集')}
            </Button>
          </span>
        </OverlayTrigger>
      ) : (
        <Button
          className={props.className}
          onClick={(): void => props.onClick()}
          variant="secondary"
        >
          {t('選択したジョブを編集')}
        </Button>
      )}
    </>
  )
}

export default EditJobsButton
