import i18next from 'i18next'

import enTranslation from '../locales/en.json'

i18next.init({
  lng: 'en',
  debug: true,
  resources: {
    en: {
      translation: enTranslation
    }
  }
})

export const langCodeToLangName = (langCode: string): string => {
  switch (langCode) {
    case 'de-DE':
      return i18next.t('German')
    case 'en-US':
      return i18next.t('English (US)')
    case 'es-MX':
      return i18next.t('Spanish (Mexico)')
    case 'fr-FR':
      return i18next.t('French')
    case 'it-IT':
      return i18next.t('Italian')
    case 'ja-JP':
      return i18next.t('Japanese')
    case 'ko-KR':
      return i18next.t('Korean')
    case 'zh-CN':
      return i18next.t('Chinese (Simplified)')
    case 'zh-TW':
      return i18next.t('Chinese (Taiwan)')
    case 'th-TH':
      return i18next.t('Thai')
    default:
      return langCode
  }
}

export const projectTypeCodeToProjectTypeName = (projectTypeCode: string): string => {
  switch (projectTypeCode) {
    case 'ht_only':
      return i18next.t('人手翻訳')
    case 'mt_and_postedit':
      return i18next.t('機械翻訳+ポストエディット')
    case 'mt_only':
      return i18next.t('機械翻訳のみ')
    default:
      return projectTypeCode
  }
}

export const contentTypeCodeToContentTypeName = (contentTypeCode: string): string => {
  switch (contentTypeCode) {
    case 'mdm-tsv':
      return 'MDM TSV'
    default:
      return contentTypeCode
  }
}

export const jobTypeCodeToJobTypeName = (jobTypeCode: string): string => {
  switch (jobTypeCode) {
    case 'mdm' || 'pim':
      return i18next.t('システム連携')
    case 'dashboard':
      return i18next.t('手動アップロード')
    default:
      return jobTypeCode
  }
}

export const userRoleCodeToUserRoleName = (userRoleCode: string): string => {
  switch (userRoleCode) {
    case 'admin':
      return i18next.t('管理者')
    case 'user':
      return i18next.t('一般ユーザー')
    default:
      return userRoleCode
  }
}

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export enum BatchStatus {
  NONE = '',
  SUBMITTED = 'SUBMITTED',
  PENDING = 'PENDING',
  RUNNABLE = 'RUNNABLE',
  STARTING = 'STARTING',
  RUNNING = 'RUNNING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED'
}

const removeDelimiter = (key: string): string => key.replace(/\/$/, '')
export const s3Keys = {
  job: {
    sourceFolder(job: { _id: string; name: string }, withDelimiter = true): string {
      const key = `jobs/${job.name}-${job._id}/workfiles/source/`
      return withDelimiter ? key : removeDelimiter(key)
    },
    translatedFolder(job: { _id: string; name: string }, withDelimiter = true): string {
      const key = `jobs/${job.name}-${job._id}/workfiles/translated/`
      return withDelimiter ? key : removeDelimiter(key)
    },
    sourceFile(job: { _id: string; name: string }): string {
      return `${this.sourceFolder(job)}${job.name}`
    },
    translatedFile(job: { _id: string; name: string }): string {
      return `${this.translatedFolder(job)}${job.name}`
    },
    translatedZip(job: { _id: string; name: string }): string {
      return `${this.translatedFolder(job)}${job.name.replace(/\.tsv$/, '_translated.zip')}`
    }
  },
  project: {
    projectFolder(project: { _id: string; name: string }, withDelimiter = true): string {
      const key = `projects/${project.name}-${project._id}/`
      return withDelimiter ? key : removeDelimiter(key)
    },
    translationKit(project: { _id: string; name: string }): string {
      return `${this.projectFolder(project)}${project.name}.zip`
    },
    translatedZip(project: { _id: string; name: string }): string {
      return `${this.projectFolder(project)}${project.name}_translated.zip`
    }
  }
}

export const sleep = (msec: number): Promise<unknown> =>
  new Promise(resolve => setTimeout(resolve, msec))

export enum OrganizationType {
  CISTATE = 'cistate',
  VENDOR = 'vendor',
  OWNER_GROUP = 'owner-group'
}

export const extractValues = (mappings: { [key: string]: any }) => {
  return Object.keys(mappings)
}

export const lookupValue = (mappings: { [key: string]: any }, key: string) => {
  return mappings[key]
}

export const lookupKey = (
  mappings: {
    [key: string]: any
  },
  name: string
): string | undefined => {
  for (const key in mappings) {
    // eslint-disable-next-line no-prototype-builtins
    if (mappings.hasOwnProperty(key)) {
      if (name === mappings[key]) {
        return key
      }
    }
  }
}
