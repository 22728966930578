import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

interface Props {
  onClick: Function
  orderByAsc: boolean
  size?: 'lg' | 'sm' | undefined
}

const ToggleSortOrderButton: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  const [orderByAsc, setOrderByAsc] = useState(false)

  const toggle = (): void => {
    props.onClick()
    setOrderByAsc(!orderByAsc)
  }

  useEffect(() => {
    setOrderByAsc(props.orderByAsc)
  }, [props])

  return (
    <Button
      className="ml-2"
      onClick={toggle}
      size={props.size ? props.size : undefined}
      variant="light"
    >
      {orderByAsc ? t('昇順') : t('降順')}
    </Button>
  )
}

export default ToggleSortOrderButton
