import React from 'react'
import { Button, Modal, Form, Row, Col, ListGroup, Badge } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { formatBytes } from '../../../lib/Utils'
import { OverlayTriggerButton } from '../../common/OverlayTriggerButton'
import { useRegisterJobsDialog, State, Action } from './useRegisterJobsDialog'
import { BackendError } from '../../../lib/BackendError'

type ContainerProps = {
  close: (updated: boolean) => void
  title: string
}

type Props = ContainerProps & {
  state: State
  dispatch: React.Dispatch<Action>
  onKeyPress: (e: React.KeyboardEvent<HTMLFormElement>) => void
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
}

export const Component: React.FC<Props> = (props: Props) => {
  const { close, handleSubmit, onKeyPress, state, dispatch } = props
  const { t } = useTranslation()

  const onSelectedFilesChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const targetFiles = e.target.files || []
    const files = Array.from(targetFiles).map(f => f)
    dispatch({ type: 'SET_SELECTED_FILES', payload: { selectedFiles: files } })
    dispatch({ type: 'SET_MESSAGE', payload: { message: '' } })
  }

  return (
    <Modal onHide={(): void => close(false)} show={true} size="lg">
      <Form onSubmit={handleSubmit} onKeyPress={onKeyPress}>
        <Modal.Header closeButton>{props.title}</Modal.Header>
        <Modal.Body>
          <Form.Group as={Row} controlId="sourceFiles">
            <Col sm={12}>
              <Form.File
                id="source-files"
                label={t('TSVファイルを選択')}
                accept=".tsv"
                onChange={onSelectedFilesChange}
                multiple
                custom
                required
                data-testid="select-tsv-file"
              ></Form.File>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="selectedFilesCount">
            <Col sm={12}>
              <div>
                {t('選択中のファイル数')}: {state.selectedFiles.length}
              </div>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="selectedFiles">
            <Col sm={12}>
              <ListGroup>
                {state.selectedFiles.map(file => (
                  <ListGroup.Item
                    key={file.name}
                    className={'d-flex justify-content-between align-items-center'}
                  >
                    {file.name}{' '}
                    <Badge pill variant="info">
                      {formatBytes(file.size)}
                    </Badge>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <span style={state.message.isError ? { color: 'red' } : undefined}>
            {state.message.text}
          </span>
          <OverlayTriggerButton
            tooltipLabel={t('アップロード中')}
            buttonLabel={t('OK')}
            isOverlayed={(): boolean => state.loading}
            isLoading={(): boolean => state.loading}
            variant="primary"
            tooltipId="tooltip-uploading-jobs"
            type="submit"
            data-testid="submit-button"
          />
          <Button
            disabled={state.loading}
            onClick={(): void => close(false)}
            style={state.loading ? { pointerEvents: 'none' } : {}}
            variant="secondary"
          >
            {t('キャンセル')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { state, dispatch, handleSubmit: uploadFiles } = useRegisterJobsDialog()

  const close = (updated: boolean): void => {
    if (!state.loading) {
      props.close(updated)
    }
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === 'Enter' /* Enter */) {
      e.preventDefault()
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    try {
      await uploadFiles()
      close(true)
    } catch (error) {
      if (error.response) {
        const errorInfo: BackendError = error.response.data
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: errorInfo.message, isError: true }
        })
      } else {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: error.message, isError: true }
        })
      }
    } finally {
      dispatch({ type: 'SET_IS_LOADING', payload: { isLoading: false } })
    }
  }

  return (
    <Component
      {...props}
      close={close}
      state={state}
      dispatch={dispatch}
      handleSubmit={handleSubmit}
      onKeyPress={onKeyPress}
    />
  )
}

Container.displayName = 'RegisterJobsDialog'
export default Container
