import React, { createContext, useContext, useReducer } from 'react'

type State = {
  canCreateTranslationKit: boolean | null
  showCancelProjectDialog: boolean
  showApproveOrderDialog: boolean
  showReturnSourceToMdmDialog: boolean
  showQuotedAmountDialog: boolean
  showRemoveJobsDialog: boolean
  showSelectJobsDialog: boolean
  showUploadTranslatedXliffDialog: boolean
  showDownloadTranslatedFilesDialog: boolean
  showSendToMdmDialog: boolean
  showCreateTranslationKitDialog: boolean
  showJobFiltersDialog: boolean
}

type Action = {
  type: string
  payload: State
}

const initialState: State = {
  canCreateTranslationKit: null,
  showCancelProjectDialog: false,
  showApproveOrderDialog: false,
  showReturnSourceToMdmDialog: false,
  showQuotedAmountDialog: false,
  showRemoveJobsDialog: false,
  showSelectJobsDialog: false,
  showUploadTranslatedXliffDialog: false,
  showDownloadTranslatedFilesDialog: false,
  showSendToMdmDialog: false,
  showCreateTranslationKitDialog: false,
  showJobFiltersDialog: false
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'UPDATE':
      return action.payload
    default:
      return state
  }
}

const ProjectDetailPageContext = createContext(
  {} as {
    state: State
    dispatch: React.Dispatch<Action>
  }
)

export const ProjectDetailPageProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <ProjectDetailPageContext.Provider value={{ state, dispatch }}>
      {children}
    </ProjectDetailPageContext.Provider>
  )
}

export const ProjectDetailPageConsumer = ProjectDetailPageContext.Consumer

export const useProjectDetailPage = () => {
  return useContext(ProjectDetailPageContext)
}
