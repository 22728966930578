import React from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

interface Props {
  className?: string
  onClick: Function
}

const RegisterJobsButton: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  return (
    <>
      <Button className={props.className} onClick={(): void => props.onClick()} variant="primary">
        {t('ジョブ登録')}
      </Button>
    </>
  )
}

export default RegisterJobsButton
