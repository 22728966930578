import React, { createContext, useContext, useReducer } from 'react'

type State = {
  srcLang: string
  tgtLang: string
  srcTmx: string
  tgtTmx: string
  confidence: string
  ownerGroupId: string
  category: string
  tmName: string
  createdAtFrom: Date | null
  createdAtTo: Date | null
}

type Action = {
  type: 'UPDATE' | 'CLEAR'
  payload: State
}

const initialState: State = {
  srcLang: '',
  tgtLang: '',
  srcTmx: '',
  tgtTmx: '',
  confidence: '',
  ownerGroupId: '',
  category: '',
  tmName: '',
  createdAtFrom: null,
  createdAtTo: null
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'UPDATE':
      return action.payload
    case 'CLEAR':
      return initialState
    default:
      return state
  }
}

const FiltersContext = createContext(
  {} as {
    state: State
    dispatch: React.Dispatch<Action>
  }
)

export const FiltersProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return <FiltersContext.Provider value={{ state, dispatch }}>{children}</FiltersContext.Provider>
}

export const FiltersConsumer = FiltersContext.Consumer

export const useFilters = () => {
  return useContext(FiltersContext)
}
