import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { Breadcrumb } from 'react-bootstrap'

import { FiltersProvider } from './Filters/useFilters'
import { TMEntriesPageProvider } from './useTMEntriesPage'

import { useTMEntriesPage } from './useTMEntriesPage'
import Toolbar from './Toolbar'
import Filters from './Filters'
import TMList from '../../components/TMList'
import EditTMDialog from '../../components/dialogs/EditTMDialog'
import ImportTmxDialog from '../../components/dialogs/ImportTmxDialog'
import ErrorMessageToast from './ErrorMessageToast'

type Props = RouteComponentProps & {
  className?: string
}

const TMEntries: React.FC<Props> = (props: Props) => {
  const { state, dispatch } = useTMEntriesPage()
  const { t } = useTranslation()

  const [showEditTMDialog, setShowEditTMDialog] = useState(false)
  const [refreshTMList, setRefreshTMList] = useState(true)
  const [showImportTmxDialog, setShowImportTmxDialog] = useState(false)
  const [inlineEditMode, setInlineEditMode] = useState(false)

  const toggleInlineEditMode = (): void => {
    setInlineEditMode(!inlineEditMode)
  }

  return (
    <TMEntriesPageProvider>
      <div className={props.className || 'Page'}>
        <Breadcrumb>
          <Breadcrumb.Item active>{t('TMエントリ一覧')}</Breadcrumb.Item>
        </Breadcrumb>
        <FiltersProvider>
          <Filters />
        </FiltersProvider>
        <Toolbar
          showEditTMDialog={(): void => setShowEditTMDialog(true)}
          showImportTmxDialog={(): void => setShowImportTmxDialog(true)}
          toggleInlineEditMode={toggleInlineEditMode}
        />
        <TMList toggleRefresh={refreshTMList} inlineEditMode={inlineEditMode} />
        {showEditTMDialog && (
          <EditTMDialog
            close={(updated: boolean): void => {
              setShowEditTMDialog(false)
              if (updated) {
                setRefreshTMList(!refreshTMList)
              }
            }}
          />
        )}
        {showImportTmxDialog && (
          <ImportTmxDialog
            close={(): void => {
              setShowImportTmxDialog(false)
            }}
          />
        )}
      </div>
      <ErrorMessageToast />
    </TMEntriesPageProvider>
  )
}

export default TMEntries
