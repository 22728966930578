import i18next from 'i18next'

import enTranslation from '../locales/en.json'

i18next.init({
  lng: 'en',
  debug: true,
  resources: {
    en: {
      translation: enTranslation
    }
  }
})

type Status =
  | 'initializing'
  | 'prepared'
  | 'quote_requested'
  | 'quote_ready'
  | 'order_confirmed'
  | 'translating'
  | 'translated'
  | 'reviewed'
  | 'completed'
  | 'cancelled'
  | 'completed_returned_to_mdm'
  | 'error'

export const statusCodeToStatusName = (statusCode: string): string => {
  switch (statusCode) {
    case 'initializing':
      return i18next.t('準備中')
    case 'prepared':
      return i18next.t('準備処理完了')
    case 'quote_requested':
      return i18next.t('見積依頼中')
    case 'quote_ready':
      return i18next.t('見積提出済み')
    case 'approved':
      return i18next.t('発注承認済み')
    case 'order_confirmed':
      return i18next.t('発注確定')
    case 'translating':
      return i18next.t('翻訳中')
    case 'translated':
      return i18next.t('翻訳済み')
    case 'reviewed':
      return i18next.t('レビュー済み')
    case 'completed':
      return i18next.t('完了')
    case 'cancelled':
      return i18next.t('キャンセル済み')
    case 'completed_returned_to_mdm':
      return i18next.t('完了（MDM戻し済み）')
    case 'error':
      return i18next.t('エラー')
    default:
      return statusCode
  }
}

export default Status
