import React from 'react'
import { Button, ButtonProps, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap'

interface OverlayTriggerButtonProps extends ButtonProps {
  isOverlayed: () => boolean
  isLoading?: () => boolean
  buttonLabel: string
  tooltipLabel: string
  tooltipId: string
  buttonClassName?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}
export const OverlayTriggerButton: React.FC<OverlayTriggerButtonProps> = ({
  isOverlayed,
  isLoading,
  buttonLabel,
  tooltipLabel,
  tooltipId,
  buttonClassName,
  onClick,
  ...otherButtonProps
}: OverlayTriggerButtonProps) => {
  if (isOverlayed()) {
    return (
      <OverlayTrigger overlay={<Tooltip id={tooltipId}>{tooltipLabel}</Tooltip>}>
        <span className="d-inline-block">
          <Button
            className={buttonClassName}
            disabled
            style={{ pointerEvents: 'none' }}
            {...otherButtonProps}
          >
            {isLoading && isLoading() && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-1"
              />
            )}
            {buttonLabel}
          </Button>
        </span>
      </OverlayTrigger>
    )
  }
  return (
    <Button onClick={onClick} className={buttonClassName} {...otherButtonProps}>
      {buttonLabel}
    </Button>
  )
}
