import { Dispatch, useCallback, useEffect, useReducer } from 'react'
import useAxios from 'axios-hooks'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import config from '../../../config'
import Job from '../../../lib/Job'
import User from '../../../lib/User'
import { BackendError } from '../../../lib/BackendError'
import OwnerGroup from '../../../lib/OwnerGroup'
import Project from '../../../lib/Project'
import { LoginUser } from '../../../hooks/useLoginUser'

type FormData = {
  name: string
  projectType: string
  ownerGroupId: string | null
  ownerId: string | null
  srcLang: string
  tgtLang: string
}

export type State = {
  loading: boolean
  users: User[]
  ownerGroups: OwnerGroup[]
  message: {
    isError: boolean
    text: string
  }
  formData: FormData
}

export type Action =
  | { type: 'SET_IS_LOADING'; payload: { isLoading: boolean } }
  | { type: 'USERS_LOADED'; payload: { users: User[] } }
  | { type: 'OWNER_GROUPS_LOADED'; payload: { ownerGroups: OwnerGroup[] } }
  | { type: 'SET_MESSAGE'; payload: { message: string; isError?: boolean } }
  | { type: 'SET_FORM_VALUE'; payload: { [key: string]: unknown } }

export const initialState: State = {
  loading: false,
  message: {
    isError: false,
    text: ''
  },
  users: [],
  ownerGroups: [],
  formData: {
    name: '',
    projectType: 'ht_only',
    ownerGroupId: null,
    ownerId: null,
    srcLang: '',
    tgtLang: ''
  }
}

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_IS_LOADING': {
      return {
        ...state,
        loading: action.payload.isLoading
      }
    }
    case 'USERS_LOADED': {
      const { users } = action.payload
      return {
        ...state,
        loading: false,
        users: users,
        formData: {
          ...state.formData,
          ownerId: users.length > 0 ? users[0]._id : null
        }
      }
    }
    case 'OWNER_GROUPS_LOADED': {
      const { ownerGroups } = action.payload
      return {
        ...state,
        loading: false,
        ownerGroups,
        formData: {
          ...state.formData,
          ownerGroupId: ownerGroups.length > 0 ? ownerGroups[0]._id : null
        }
      }
    }
    case 'SET_MESSAGE': {
      return {
        ...state,
        message: {
          text: action.payload.message,
          isError: action.payload.isError ?? false
        }
      }
    }
    case 'SET_FORM_VALUE': {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload
        }
      }
    }
    default: {
      return state
    }
  }
}

type UseRegisterProjectDialog = {
  (loginUser: LoginUser | null, jobs: Job[]): {
    state: State
    dispatch: Dispatch<Action>
    handleSubmit: () => Promise<Project | undefined>
  }
}

export const useRegisterProjectDialog: UseRegisterProjectDialog = (loginUser, jobs) => {
  const [state, dispatch] = useReducer(stateReducer, initialState)
  const { t } = useTranslation()
  const [, fetchUsers] = useAxios<User[]>(`${config[config.STAGE].endpoint}/api/v1/users`, {
    manual: true
  })
  const [, fetchOwnerGroups] = useAxios<OwnerGroup[]>(
    `${config[config.STAGE].endpoint}/api/v1/ownerGroups`,
    { manual: true }
  )

  useEffect(() => {
    // 渡されたjobsの最初のjob.srcLang/tgtLangをプロジェクトのsrcLang/tgtLangとする
    if (!jobs || jobs.length === 0) return
    dispatch({
      type: 'SET_FORM_VALUE',
      payload: {
        srcLang: jobs[0].srcLang,
        tgtLang: jobs[0].tgtLang
      }
    })
  }, [jobs])

  useEffect(() => {
    // loginUserがcistateの場合、全owernGroupを設定する
    // loginUserがowner-groupの場合、自身のownerGroupを設定する
    const loadOwnerGroups = async (): Promise<void> => {
      if (!loginUser) {
        return
      }
      if (loginUser.organizationType !== 'cistate') {
        const ownerGroups: OwnerGroup[] = loginUser.organizations as OwnerGroup[]
        dispatch({ type: 'OWNER_GROUPS_LOADED', payload: { ownerGroups } })
      } else {
        dispatch({ type: 'SET_IS_LOADING', payload: { isLoading: true } })
        const response = await fetchOwnerGroups()
        dispatch({ type: 'OWNER_GROUPS_LOADED', payload: { ownerGroups: response.data ?? [] } })
      }
    }
    loadOwnerGroups()
  }, [fetchOwnerGroups, loginUser])

  useEffect(() => {
    // 選択中のownerGroupに所属するユーザーを取得する
    if (!state.formData.ownerGroupId) {
      return
    }
    const loadUsers = async (): Promise<void> => {
      dispatch({ type: 'SET_IS_LOADING', payload: { isLoading: true } })
      const response = await fetchUsers({
        params: {
          isEnabled: true,
          organizationIds: state.formData.ownerGroupId
        }
      })
      dispatch({ type: 'USERS_LOADED', payload: { users: response.data ?? [] } })
    }
    loadUsers()
  }, [fetchUsers, state.formData.ownerGroupId])

  const handleSubmit = useCallback(async (): Promise<Project | undefined> => {
    try {
      dispatch({ type: 'SET_IS_LOADING', payload: { isLoading: true } })
      dispatch({ type: 'SET_MESSAGE', payload: { message: t('プロジェクトを作成しています') } })
      const projectResponse = await axios.post<Project>(
        `${config[config.STAGE].endpoint}/api/v1/projects`,
        state.formData
      )

      dispatch({ type: 'SET_MESSAGE', payload: { message: t('ジョブを束ねています') } })
      await Promise.all(
        jobs.map(async (job: Job) => {
          return await axios.patch(`${config[config.STAGE].endpoint}/api/v1/jobs/${job._id}`, {
            projectId: projectResponse.data._id
          })
        })
      )

      dispatch({ type: 'SET_MESSAGE', payload: { message: t('文字数を再計算しています') } })
      const recalculateResponse = await axios.post<Project>(
        `${config[config.STAGE].endpoint}/api/v1/projects/${projectResponse.data._id}/recalculate`
      )
      return recalculateResponse.data
    } catch (error) {
      let message
      if (error.response) {
        const errorInfo: BackendError = error.response.data
        message = errorInfo.message
      } else {
        message = error.message
      }
      dispatch({ type: 'SET_MESSAGE', payload: { isError: true, message } })
    } finally {
      dispatch({ type: 'SET_IS_LOADING', payload: { isLoading: false } })
    }
  }, [jobs, state, t])

  return {
    state,
    dispatch,
    handleSubmit
  }
}
